<template>
  <div
    v-if="isOpen"
    v-show="isRedraw"
    class="add-payment-method-popup"
  >
    <div class="popup">
      <div class="head">
        <span class="name">
          {{ paymentId ? $t('settings.paymentMethods.popup.titleEdit') : $t('settings.paymentMethods.popup.title') }}
        </span>
        <ui-icon
          :size="20"
          lib="fa"
          substyle="fas"
          class="close"
          name="times"
          @click.native="close"
        />
      </div>
      <adm-ui-scrollbar
        wrap-style="max-height: 75vh; height: auto;"
        :native="false"
      >
        <div
          v-loading="isLoading"
          class="body"
        >
          <div class="ui-fg-1">
            <ui-input
              v-model="data.name"
              class="form-input ui-m-xl-b"
              :label="$t('settings.paymentMethods.popup.name')"
              autosize
              size="big"
              :placeholder="$t('settings.paymentMethods.popup.placeholderName')"
              type="text"
              :error="getError('name')"
            />

            <div class="ui-m-md-b row-bg">
              <div class="ui-d-flex ui-ai-center">
                {{ $t('settings.paymentMethods.popup.status') }}
                <el-tooltip
                  :content="$t('settings.paymentMethods.popup.statusHint')"
                  placement="bottom"
                  :open-delay="300"
                >
                  <ui-icon
                    name="question-circle"
                    class="ui-m-sm-l question-circle"
                    color="var(--tooltip-icon)"
                    :size="14"
                    lib="fa-6"
                    substyle="fa-solid"
                  />
                </el-tooltip>
              </div>
              <div class="ui-d-flex ui-ai-center">
                <span :style="{ color: data.isDisabled ? $theme.dangerColor : $theme.accentColor}" class="toggle-label">
                  {{ $t(`settings.paymentMethods.statuses.${data.isDisabled ? 'disabled' : 'active'}`) }}
                </span>
                <el-switch
                  v-model="status"
                  class="ui-m-sm-l"
                  :active-color="$theme.accentColor"
                  :inactive-color="$theme.dangerColor"
                />
              </div>
            </div>
            <div v-if="getError('is_disabled')" class="field-error">
              {{ getError('is_disabled') }}
            </div>
            <div class="ui-m-md-b row-bg">
              <div class="ui-d-flex ui-ai-center">
                {{ $t('settings.paymentMethods.popup.visibility') }}
                <el-tooltip
                  :content="$t('settings.paymentMethods.popup.visibilityHint')"
                  placement="bottom"
                  :open-delay="300"
                >
                  <ui-icon
                    name="question-circle"
                    class="ui-m-sm-l question-circle"
                    color="var(--tooltip-icon)"
                    :size="14"
                    lib="fa-6"
                    substyle="fa-solid"
                  />
                </el-tooltip>
              </div>
              <div class="ui-d-flex ui-ai-center">
                <span :style="{ color: data.visibility ? $theme.accentColor : $theme.dangerColor}" class="toggle-label">
                  {{ $t(`settings.paymentMethods.${data.visibility ? 'visible' : 'hidden'}`) }}
                </span>
                <el-switch
                  v-model="data.visibility"
                  class="ui-m-sm-l"
                  :disabled="data.isDisabled"
                  :active-color="$theme.accentColor"
                  :inactive-color="$theme.dangerColor"
                />
              </div>
            </div>
            <div v-if="getError('hidden')" class="field-error">
              {{ getError('hidden') }}
            </div>
            <div class="ui-m-md-b row-bg">
              <div class="ui-d-flex ui-ai-center">
                {{ $t('settings.paymentMethods.popup.export') }}
              </div>
              <div class="ui-d-flex ui-ai-center">
                <span :style="{ color: data.exportViaApi ? $theme.accentColor : $theme.dangerColor}" class="toggle-label">
                  {{ $t(`settings.paymentMethods.popup.${data.exportViaApi ? 'yes' : 'no'}`) }}
                </span>
                <el-switch
                  v-model="data.exportViaApi"
                  :disabled="data.isDisabled"
                  class="ui-m-sm-l"
                  :active-color="$theme.accentColor"
                  :inactive-color="$theme.dangerColor"
                />
              </div>
            </div>
            <div v-if="getError('export_via_api')" class="field-error">
              {{ getError('export_via_api') }}
            </div>
            <div class="row-bg">
              <div class="ui-d-flex ui-ai-center">
                {{ $t('settings.paymentMethods.popup.fileAttachments') }}
              </div>
              <el-radio-group
                v-model="fileAttachments"
                class="file-attachments-radio"
                size="medium"
              >
                <el-radio label="no">
                  {{ $t('settings.paymentMethods.popup.no') }}
                </el-radio>
                <el-radio label="allowed">
                  {{ $t('settings.paymentMethods.popup.allowed') }}
                </el-radio>
                <el-radio label="required">
                  {{ $t('settings.paymentMethods.popup.required') }}
                </el-radio>
              </el-radio-group>
            </div>
            <div v-if="getError('file_attachment_allowed') || getError('file_attachment_required')" class="field-error">
              {{ getError('file_attachment_allowed') || getError('file_attachment_required') }}
            </div>

            <el-divider class="divider" />

            <div class="row ui-m-xl-b">
              <div class="field">
                <div class="label-text">
                  {{ $t('settings.paymentMethods.popup.currencies') }}
                </div>
                <el-radio-group
                  v-model="supportedCurrencies"
                  class="file-attachments-radio ui-m-md-t"
                  size="medium"
                >
                  <el-radio label="all">
                    {{ $t('settings.paymentMethods.popup.all') }}
                  </el-radio>
                  <el-radio label="only_selected">
                    {{ $t('settings.paymentMethods.popup.selected') }}
                  </el-radio>
                </el-radio-group>

                <ui-select
                  v-model="selectedCurrencies"
                  :placeholder="$t('settings.paymentMethods.popup.placeholderCurrencies')"
                  :options="currencyOptions"
                  width="100%"
                  :disabled="supportedCurrencies === 'all'"
                  :multiple="true"
                  :filterable="true"
                  :remote="false"
                  value-prop="code"
                  label-prop="name"
                  list-label-prop="code"
                  :max-count-tags="5"
                  class="ui-m-md-t"
                  :class="{error: getError('currency_codes')}"
                />
                <div v-if="getError('currency_codes')" class="field-error">
                  {{ getError('currency_codes') }}
                </div>
              </div>
            </div>

            <ui-input
              v-model="data.walletRegexp"
              class="form-input ui-m-xl-b"
              :label="$t('settings.paymentMethods.popup.walletRegexp')"
              autosize
              size="big"
              :placeholder="$t('settings.paymentMethods.popup.walletRegexp')"
              type="text"
              :error="getError('wallet_regexp')"
            />

            <ui-input
              v-model="data.walletPlaceholder"
              class="form-input ui-m-xl-b"
              :label="$t('settings.paymentMethods.popup.walletPlaceholder')"
              autosize
              size="big"
              :placeholder="$t('settings.paymentMethods.popup.walletPlaceholder')"
              type="text"
              :error="getError('wallet_placeholder')"
            />

            <div class="row ui-m-xl-b">
              <div class="field">
                <span class="label-text">{{ $t('settings.paymentMethods.popup.requiredAffiliateInfo') }}</span>
                <el-select
                  v-model="data.requiredAffiliateInfo"
                  :placeholder="$t('settings.paymentMethods.popup.notSelected')"
                  class="select"
                  multiple
                  size="small"
                  :class="{ error: getError('required_affiliate_fields') }"
                >
                  <el-option
                    v-for="item in requiredAffiliateOptions"
                    :key="item"
                    :label="$t(`settings.paymentMethods.popup.requiredOptions.${item}`)"
                    :value="item"
                  />
                </el-select>
                <div v-if="getError('required_affiliate_fields')" class="field-error">
                  {{ getError('required_affiliate_fields') }}
                </div>
              </div>
            </div>

            <div class="row ui-m-xl-b">
              <div class="field">
                <span class="label-text">{{ $t('settings.paymentMethods.popup.minimalPaymentAmountCurrency') }}</span>
                <el-select
                  v-model="data.minimalPaymentAmountCurrencyCode"
                  :placeholder="$t('settings.paymentMethods.popup.notSelected')"
                  class="select"
                  :class="{ error: getError('min_payment_amount_currency_code') }"
                >
                  <el-option
                    v-for="item in minimalPaymentAmountCurrencyOptions"
                    :key="item.code"
                    :label="item.name"
                    :value="item.code"
                  />
                </el-select>
                <div v-if="getError('min_payment_amount_currency_code')" class="field-error">
                  {{ getError('min_payment_amount_currency_code') }}
                </div>
              </div>
            </div>

            <ui-input
              v-model="data.minimalPaymentAmount"
              class="form-input ui-m-xl-b"
              :label="$t('settings.paymentMethods.popup.minimalPaymentAmount')"
              autosize
              size="big"
              placeholder="0"
              type="text"
              :error="getError('min_payment_amount')"
              @change="preventSymbol"
            />

            <el-divider class="divider" />

            <div class="ui-m-xl-b" :style="{ ...(languages.length && {marginTop: '-8px'})}">
              <crm-action-language-tab
                v-if="languages.length"
                :languages="languages"
                class="ui-m-md-b"
                :selected-index="index"
                :errors="mapDescriptionErrors"
                @select="handleSelectTab"
              />
              <div class="ui-d-flex ">
                <crm-action-languages
                  class="ui-fg-0"
                  :selected="selectedLanguages"
                  @select="handleAddLanguage"
                />
                <ui-button
                  icon="times"
                  lib="fa"
                  substyle="fas"
                  color="red"
                  class="action-btn ui-m-md-l"
                  :disabled="defaultLanguageIndex === index"
                  @click="handleDelete(index)"
                >
                  {{ $t('crm.buttons.delete') }}
                </ui-button>
              </div>
            </div>

            <div class="row">
              <div class="field">
                <span class="label-text">{{ $t(`settings.paymentMethods.popup.description`) }}</span>
                <el-input
                  v-model="descriptionValue"
                  class="description"
                  :disabled="!languages.length"
                  :placeholder="$t(`settings.paymentMethods.popup.descriptionPlaceholder`)"
                  :rows="3"
                  resize="none"
                  :class="{ error: getError(['description', descriptionCode]) }"
                  type="textarea"
                />
              </div>
              <div v-if="getError(['description', descriptionCode])" class="field-error">
                {{ getError(['description', descriptionCode]) }}
              </div>
            </div>

            <div class="row ui-p-sm-b">
              <UploadFile
                v-model="data.files"
                accept=".png, .jpg"
                :limit-file="1"
                class="upload-file"
                :upload-files-action="uploadFile"
                :remove-file="removeFile"
              >
                <template slot="title">
                  <div class="ui-m-xl-t">
                    <span class="upload-title">{{ $t('settings.paymentMethods.popup.logo') }}</span>
                  </div>
                </template>
                <template slot="upload-text">
                  <span class="upload-text" :class="{ disabled: data.files.length > 0}">{{ $t('settings.paymentMethods.popup.dropFileHereOr') }} <em>{{ $t('settings.paymentMethods.popup.selectFile') }}</em></span>
                </template>
              </UploadFile>
              <div v-if="getError('files') || getError('logo_base64')" class="field-error">
                {{ getError('logo_base64') || getError('files') }}
              </div>
            </div>
          </div>
        </div>
      </adm-ui-scrollbar>
      <div class="foot">
        <ui-button
          color="red"
          lib="fa"
          substyle="fas"
          icon="times"
          :disabled="isLoading"
          @click="close()"
        >
          {{ $t('crm.buttons.cancel') }}
        </ui-button>
        <ui-button
          :color="$theme.mainColor"
          :disabled="isLoading"
          filled
          lib="fa"
          substyle="fas"
          icon="check"
          @click="handleSave()"
        >
          {{ $t('crm.buttons.save') }}
        </ui-button>
      </div>
    </div>
    <div class="wrapper" />
  </div>
</template>


<script>
import CrmActionLanguages from '@/views/CRM/components/CrmActionLanguages.vue';
import CrmActionLanguageTab from '@/views/CRM/components/CrmActionLanguageTab.vue';
import ActivitiesLanguageMixin from '@/views/CRM/mixins/activities-language-mixin';
import UploadFile from '@/components/UploadFile';
import errorHandleMixin from '@/views/mixins/error-hadle';

const getBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});
export default {
  name: 'AddSmsActionPopup',
  components: {
    CrmActionLanguages,
    CrmActionLanguageTab,
    UploadFile,
  },
  mixins: [
    ActivitiesLanguageMixin,
    errorHandleMixin,
  ],
  props: {
    currencyOptions: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      isOpen: false,
      isLoading: true,
      isRedraw: false,
      index: -1,
      fileAttachments: 'no',
      paymentId: null,
      supportedCurrencies: 'all',
      minAmountCurrencies: [],
      data: {
        ...this.getDefaultForm(),
      },
    };
  },

  computed: {
    defaultLanguageIndex() {
      return this.selectedLanguages.findIndex(language_code => language_code === 'en');
    },
    requiredAffiliateOptions() {
      return ['phone_number', 'country_code', 'address', 'postal_code'];
    },
    status: {
      get() {
        return !this.data.isDisabled;
      },
      set(val) {
        this.data.isDisabled = !val;
      },
    },
    descriptionValue: {
      get() {
        return this.$_.get(this.data, ['description', this.index, 'msg'], '');
      },
      set(value) {
        this.data.description[this.index].msg = value;
      },
    },
    descriptionCode() {
      return this.$_.get(this.data, ['description', this.index, 'language_code'], '');
    },
    languages() {
      const languages = this.$store.state.misc.languages || [];

      return this.data.description.map(action => ({
        ...action,
        ...(languages.find(language => language.code === action.language_code) || {}),
      }));
    },
    mapDescriptionErrors() {
      return this.data.description.map(({ language_code }) => this.$_.get(this.errors, ['description', language_code], null));
    },
    selectedCurrencies: {
      get() {
        return this.data.currenciesCodes;
      },
      set(value) {
        this.data.currenciesCodes = value;
        this.minimalPaymentAmountCurrencyOptions = value;

        if (!value.includes(this.data.minimalPaymentAmountCurrencyCode)) {
          const [firstAvailableOption] = value;
          this.data.minimalPaymentAmountCurrencyCode = firstAvailableOption;
        }
      },
    },
    minimalPaymentAmountCurrencyOptions: {
      get() {
        return this.supportedCurrencies === 'all' ? this.currencyOptions : this.minAmountCurrencies;
      },
      set(value) {
        this.minAmountCurrencies = this.currencyOptions.filter(item => value.includes(item.code));
      },
    },
  },
  watch: {
    'data.isDisabled': {
      handler(val) {
        if (val) {
          this.data.visibility = false;
          this.data.exportViaApi = false;
        }
      },
    },
    fileAttachments(key) {
      if (key === 'no') {
        this.data.fileAttachmentAllowed = false;
        this.data.fileAttachmentRequired = false;
      }
      if (key === 'allowed') {
        this.data.fileAttachmentAllowed = true;
        this.data.fileAttachmentRequired = false;
      }
      if (key === 'required') {
        this.data.fileAttachmentAllowed = true;
        this.data.fileAttachmentRequired = true;
      }
    },
  },
  created() {},
  mounted() {},
  methods: {
    preventSymbol(e) {
      if (!(/^[0-9]*\.?[0-9]*$/).test(e.key) && ![8, 46].includes(e.keyCode)) {
        e.preventDefault();
      }
    },
    uploadFile(asset) {
      if (!asset) return;

      this.errors.files = null;

      if (asset.file.size > 100000) { // 100kb
        this.errors.files = [{ message: this.$t('settings.paymentMethods.popup.limitFileSize') }];
        this.data.files = [];
        return;
      }

      getBase64(asset.file).then((res) => {
        this.data.files = [{
          uid: asset.file.uid,
          name: asset.file.name,
          file: res,
        }];
      });
    },
    removeFile() {
      this.data.files = [];
    },
    open(id) {
      this.isRedraw = false;
      this.isOpen = true;
      this.index = -1;

      this.$nextTick(() => {
        setTimeout(() => {
          this.isRedraw = true;
        }, 100);
      });

      this.fileAttachments = 'no';
      this.data = this.getDefaultForm();
      this.errors = {};
      this.paymentId = id;

      if (id) {
        this.isLoading = true;
        this.$api.getControlPaymentMethodsById(id).then((res) => {
          const {
            name,
            export_via_api,
            hidden,
            is_disabled,
            description,
            file_attachment_allowed,
            file_attachment_required,
            logo_base64,
            min_payment_amount,
            min_payment_amount_currency_code,
            wallet_regexp,
            wallet_placeholder,
            required_affiliate_fields,
            currencies,
          } = res.data.payload;
          this.supportedCurrencies = currencies.length ? 'only_selected' : 'all';

          this.data = {
            name,
            isDisabled: is_disabled,
            visibility: !hidden,
            exportViaApi: export_via_api,
            walletRegexp: wallet_regexp,
            walletPlaceholder: wallet_placeholder,
            requiredAffiliateInfo: required_affiliate_fields,
            description: Object.entries({ en: '', ...description }).map(([language_code, msg]) => ({
              language_code,
              msg,
            })).sort((a, b) => {
              if (a.language_code === 'en') {
                return -1;
              }
              if (b.language_code === 'en') {
                return 1;
              }
              return a.language_code.localeCompare(b.language_code);
            }),
            files: logo_base64 ? [{
              uid: 1,
              name: 'File uploaded',
              file: logo_base64,
            }] : [],
            fileAttachmentAllowed: file_attachment_allowed,
            fileAttachmentRequired: file_attachment_required,
            minimalPaymentAmountCurrencyCode: min_payment_amount_currency_code,
            minimalPaymentAmount: min_payment_amount,
          };

          this.selectedCurrencies = currencies.map(({ code }) => code);
          this.data.minimalPaymentAmountCurrencyCode = min_payment_amount_currency_code;

          this.setFileAttachments({ fileAttachmentAllowed: file_attachment_allowed, fileAttachmentRequired: file_attachment_required });
        })
          .catch(() => this.close())
          .finally(() => {
            setTimeout(() => {
              this.isLoading = false;
            }, 300);
          });
      } else {
        this.isLoading = false;
      }

      this.index = this.defaultLanguageIndex;
    },
    close() {
      this.isOpen = false;
      this.isLoading = true;
    },
    handleSave() {
      const {
        name,
        files,
        exportViaApi,
        visibility,
        isDisabled,
        fileAttachmentAllowed,
        fileAttachmentRequired,
        minimalPaymentAmount,
        minimalPaymentAmountCurrencyCode,
        description,
        walletRegexp,
        walletPlaceholder,
        requiredAffiliateInfo,
        currenciesCodes,
      } = this.data;

      const filteredDescription = description
        .filter(({ msg }) => !!msg)
        .reduce((acc, { language_code, msg }) => {
          acc[language_code] = msg;
          return acc;
        }, {});

      const params = {
        name,
        ...(files.length && { logo_base64: files[0].file }),
        export_via_api: exportViaApi,
        file_attachment_allowed: fileAttachmentAllowed,
        file_attachment_required: fileAttachmentRequired,
        hidden: !visibility,
        is_disabled: isDisabled,
        min_payment_amount: +minimalPaymentAmount || 0,
        min_payment_amount_currency_code: minimalPaymentAmountCurrencyCode,
        wallet_regexp: walletRegexp,
        wallet_placeholder: walletPlaceholder,
        required_affiliate_fields: requiredAffiliateInfo,
        description: this.$_.isEmpty(filteredDescription) ? null : filteredDescription,
        ...(this.supportedCurrencies === 'only_selected' && { currency_codes: currenciesCodes }),
        supported_currencies: this.supportedCurrencies,
      };

      const promise = this.paymentId ? this.$api.putControlPaymentMethods(this.paymentId, params) : this.$api.postControlPaymentMethods(params);

      promise
        .then(() => {
          this.close();
          this.$emit('save');
        })
        .catch((e) => {
          this.errors = e.data.errors;
        });
    },
    handleAddLanguage(languageCode) {
      this.data.description.push({
        language_code: languageCode,
        msg: '',
      });
      this.index = this.data.description.length - 1;
    },
    handleDelete(index) {
      this.index = this.defaultLanguageIndex;
      this.data.description.splice(index, 1);
    },
    setFileAttachments({ fileAttachmentAllowed, fileAttachmentRequired }) {
      if (fileAttachmentAllowed && fileAttachmentRequired) {
        this.fileAttachments = 'required';
      } else if (fileAttachmentAllowed && !fileAttachmentRequired) {
        this.fileAttachments = 'allowed';
      } else {
        this.fileAttachments = 'no';
      }
    },
    getDefaultForm() {
      return {
        name: '',
        isDisabled: false,
        visibility: false,
        exportViaApi: false,
        walletRegexp: '',
        walletPlaceholder: '',
        requiredAffiliateInfo: '',
        description: [
          {
            language_code: 'en',
            msg: '',
          },
        ],
        files: [],
        fileAttachmentAllowed: false,
        fileAttachmentRequired: false,
        minimalPaymentAmount: null,
        minimalPaymentAmountCurrencyCode: null,
        currenciesCodes: [],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.add-payment-method-popup {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 900;

  .wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.5;
    background-color: #303634;
    z-index: 901;
    width: 100%;
  }

  .row-bg {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 4px;
    background-color: var(--crm-panel-bg);
    height: 32px;

    font-size: 14px;
    font-weight: 500;
  }

  .popup {
    display: flex;
    flex-direction: column;
    z-index: 902;
    top: 8vh;
    width: 480px;
    border-radius: 8px;
    background-color: white;
    box-sizing: border-box;


    .head {
      padding: 16px 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #eaeaea;
      .name {
        font-size: 20px;
        font-weight: 500;
        display: flex;
        align-items: center;
      }
      .close {
        font-size: 15px;
        cursor: pointer;
        &:hover {
          color: var(--main-color) !important;
        }
      }
    }

    .body {
      padding: 16px 24px;
      display: flex;
      position: relative;
      max-height: 84vh;
      min-height: 215px;

      .content-header{
        margin-bottom: 16px;
        display: flex;
        align-items: flex-start;
      }

      .select-wrap {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        margin-right: 16px;

        &_grow{
          flex-grow: 1;
        }

        &__label{
          font-size: 14px;
          margin-bottom: 5px;
        }
        &:last-child{
          margin-right: 0;
        }
      }

      .card__title{
        .card-content{
          .label{
            font-size: 24px;
            font-weight: 500;
            line-height: 1.25;
          }
        }
      }
    }

    .foot {
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid #eaeaea;
      padding: 16px 24px;
      .ui-button + .ui-button{
        margin-left: 8px;
      }
    }
  }

  .select-block {
    display: flex;
    flex-direction: column;

    .label {
      font-size: 14px;
      padding-bottom: 5px;
    }
  }

  .file-attachments-radio {

    /deep/ {
      .el-radio + .el-radio {
        margin-left: 10px;
      }

      .el-radio {
        margin-right: 0;

        .el-radio__label {
          font-size: 12px;
          font-weight: 400;
        }
      }
    }
  }

  .divider {
    margin-top: 16px;
    margin-bottom: 16px;
    background-color: var(--border-color);

    &.active {
      margin-bottom: 8px;
    }
  }

  .label-text {
    font-size: 14px;
    color: var(--crm-panel-text);
    margin-bottom: 5px;
    display: block;
  }

  .select {
    width: 100%;

    /deep/ {
      .el-input__suffix-inner {
        align-items: center;
        display: flex;
      }
    }

    &.error {
      /deep/ {
        .el-input__inner {
          border-color: var(--danger-color);
        }
      }
    }
  }

  .toggle-label {
    font-weight: 400;
    font-size: 12px;
  }

  /deep/ {
    .crm-language-tab__item-content {
      padding-top: 0;
    }
    .crm-language-tab__item {
      margin-top: 8px;
    }
  }

  .field-error {
    text-align: right;
  }

  .upload-file {
    /deep/ {
      .upload {
        margin-top: 5px;

        .el-upload-dragger {
          padding: 10px;
        }
      }
    }
  }

  .description.error {
    /deep/ {
      textarea {
        border-color: var(--danger-color);
      }
    }
  }

  .form-input {
    /deep/ {
      .input-error {
        text-transform: initial!important;
        font-weight: 400!important;
      }
      input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }

  .upload-text {
    width: 100%;
    color: #7d7d7d;
    font-size: 13px;

    em {
      color: #20815e;
    }

    &.disabled {
      em {
        color: #7d7d7d;
      }

    }
  }

  .question-circle {
    cursor: pointer;
  }
}
</style>
