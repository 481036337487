<template>
  <div>
    <div
      :class="action"
      class="link-affiliate-popup"
    >
      <div class="popup">
        <div class="head">
          <span class="name">{{ $t('players.pinPlayer.pinned') }}-{{ data.site_player_id }} {{ $t('players.pinPlayer.to_affiliate') }}</span>
          <ui-icon
            :size="19"
            class="close"
            lib="fa"
            substyle="fas"
            name="times"
            @click.native="close"
          />
        </div>
        <div
          v-loading="isDataLoading"
          class="form"
        >
          <div class="row">
            <span
              v-if="action === 'new'"
              class="text-top"
            >{{ $t('players.pinPlayer.top_text_1') }}-{{ data.site_player_id }}</span>
            <span
              v-if="action === 'update'"
              class="text-top"
            >{{ $t('players.pinPlayer.top_text_2') }} <br>{{ $t('players.pinPlayer.player') }}-{{ data.site_player_id }} {{ $t('players.pinPlayer.or_unpin') }}</span>
          </div>
          <div class="row">
            <div class="field">
              <el-tooltip
                disabled
                :content="$t('players.pinPlayer.aff_req')"
                placement="top"
              >
                <span class="label">{{ $t('players.pinPlayer.aff') }}</span>
              </el-tooltip>
              <div class="wrap-unpin">
                <el-select
                  v-model="affiliateId"
                  :placeholder="$t('players.pinPlayer.placeholder_aff')"
                  :loading="loading"
                  :remote-method="searchMethod"
                  filterable
                  class="select"
                  popper-class="select-affiliate-list"
                  remote
                  @visible-change="handleToggleSelect"
                  @input="changeAffiliate"
                >
                  <el-option
                    v-for="item in getAffiliate"
                    :key="item.id"
                    :label="item.email"
                    :value="item.id"
                  />
                </el-select>
                <ui-button
                  v-if="action === 'update'"
                  :disabled="cloneAffiliateId !== affiliateId"
                  size="big"
                  color="red"
                  class="btn"
                  lib="fa"
                  substyle="fas"
                  icon="unlink"
                  @click="clickConfirmHandler('detach')"
                >
                  {{ $t('players.pinPlayer.unpin') }}
                </ui-button>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="field">
              <span class="label">{{ $t('players.pinPlayer.offer') }}</span>
              <el-select
                v-model="offerId"
                :disabled="disabledOffer"
                :placeholder="$t('players.pinPlayer.placeholder_offer')"
                filterable
                class="select"
                :no-data-text="$t('players.info.no_data')"
                :no-match-text="$t('players.info.no_data')"
              >
                <el-option
                  v-for="(item) in affiliateOffers"
                  :key="item.id"
                  :label="item.full_name"
                  :value="item.id"
                />
              </el-select>
            </div>
          </div>
        </div>

        <div class="foot">
          <ui-button
            size="big"
            color="red"
            class="btn"
            lib="fa"
            substyle="fas"
            icon="times"
            @click.native="close"
          >
            {{ $t('players.pinPlayer.cancel') }}
          </ui-button>
          <ui-button
            v-if="action === 'new'"
            :disabled="disabledLink"
            filled
            size="big"
            icon="link"
            lib="fa"
            substyle="fas"
            color="green"
            @click="attach"
          >
            {{ $t('players.pinPlayer.pin_aff') }}
          </ui-button>
          <ui-button
            v-if="action === 'update'"
            :disabled="disabledLink"
            filled
            size="big"
            lib="fa"
            substyle="fas"
            icon="check"
            color="green"
            @click="clickConfirmHandler('attach')"
          >
            {{ $t('players.pinPlayer.save') }}
          </ui-button>
        </div>
      </div>
      <div class="wrapper" />
    </div>
    <ui-confirm
      ref="confirm"
      :width="480"
      :type="confirm.type"
      :action-name="confirm.actionName"
      :action="confirm.action"
      action-icon="unlink"
      @save="changeStatus"
    >
      <div slot="title">
        {{ confirm.title }}
      </div>
      <div class="body__wrap">
        <i
          :class="confirm.type"
          class="fas fa-exclamation-triangle"
        />
        <div
          class="body__msg"
          v-html="confirm.msg"
        />
      </div>
    </ui-confirm>
  </div>
</template>

<script>


export default {
  name: 'LinkAffiliatePopup',
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    siteId: {
      type: String,
      default: '',
    },
    isOpen: {
      type: Boolean,
      required: true,
    },

  },
  data() {
    return {
      isDataLoading: true,
      getAffiliate: [],
      affiliateId: null,
      cloneAffiliateId: null,
      offerId: null,
      affiliateOffers: [],
      disabledOffer: true,
      hiddenBtnUnlink: false,
      paramsAttach: {},
      pinnedAffiliateId: null,
      pinnedAffiliateOfferId: null,
      action: '',
      confirm: {
        title: '',
        msg: '',
        type: '',
        action: '',
        actionName: '',
      },
      getAffiliates: Function,
      // переменные для селекта с инфинити загнрузкой списка
      limit: 33,
      // eslint-disable-next-line
      _showCount: 33,
      searchQuery: '',
      maxCount: Number,
      stopFlag: false,
      loading: true,
    };
  },
  computed: {
    disabledLink() {
      if ((this.pinnedAffiliateId === this.affiliateId && this.pinnedAffiliateOfferId === this.offerId) || this.offerId === null) {
        return true;
      }
      return false;
    },
  },

  watch: {
    isOpen(e) {
      // open
      if (e) {
        this.resetForm();
        this.pinnedAffiliateId = this.$_.clone(this.data.affiliate_id);
        this.pinnedAffiliateOfferId = this.$_.clone(this.data.affiliate_offer_id);
        // already pinned
        if (this.data.affiliate_id !== null) {
          this.isDataLoading = true;
          this.action = 'update';
          this.disabledOffer = false;
          this.hiddenBtnUnlink = true;
          this.affiliateId = this.data.affiliate_id;
          this.cloneAffiliateId = this.$_.clone(this.data.affiliate_id);
          this.getAffiliates(this.affiliateId);
          this.offerId = this.data.affiliate_offer_id;
          this.getAffiliateOffers(this.affiliateId);
          // }
        }
        // not pinned
        if (this.data.affiliate_id === null) {
          this.isDataLoading = false;
          this.action = 'new';
          // this.getAffiliates();
        }
      }
    },
  },
  mounted() {
    this.getAffiliates = this.$_.debounce(this.getAffiliatesD, 50);
    window.addEventListener('keyup', (e) => {
      if (e.key === 'Escape' && this.isOpen) {
        this.close();
      }
    });
  },

  methods: {
    searchMethod(v) {
      // this.loading = true;
      this.searchQuery = v;
      this.limit = 33;
      this.$_.debounce(this.getAffiliatesD, 800)(this.searchQuery, this.limit);
    },
    handleToggleSelect(v) {
      if (v) {
        this.getListItem('add');
        this.limit = 33;
        this.loading = true;
        this.getAffiliatesD(this.searchQuery, this.limit).then(() => {
          this.loading = false;
        });
      } else {
        this.getListItem('remove');
        this.stopFlag = false;
      }
    },
    getListItem(action) {
      const dropList = document.querySelector('.select-affiliate-list .el-select-dropdown__wrap');
      if (this.stopFlag) {
        dropList.removeEventListener('scroll', this.eventScroll);
        return;
      }
      if (action === 'add') {
        this.searchQuery = '';
        dropList.addEventListener('scroll', this.eventScroll);
      } else if (action === 'remove') {
        dropList.removeEventListener('scroll', this.eventScroll);
        this.searchQuery = '';
        this.limit = 33;
      }
    },
    eventScroll() {
      const dropList = document.querySelector('.select-affiliate-list .el-select-dropdown__wrap');

      if (+this.maxCount - this.limit < 0) {
        return;
      }
      // eslint-disable-next-line
      if (+this.maxCount - this.limit === 0 && this.stopFlag) {
      } else if ((dropList.scrollTop - dropList.scrollHeight) + dropList.clientHeight === 0 && +this.maxCount - this.limit <= 33) {
        this.getAffiliates(this.searchQuery, this.maxCount);
        this.limit = this.maxCount;
        this.stopFlag = true;
      } else if ((dropList.scrollTop - dropList.scrollHeight) + dropList.clientHeight === 0) {
        this.limit = this.limit + 33; // _showCount
        this.getAffiliates(this.searchQuery, this.limit);
      }
    },

    changeAffiliate(id) {
      this.offerId = null;
      this.getAffiliateOffers(id).then(() => { this.disabledOffer = false; });
    },

    resetForm() {
      this.isDataLoading = true;
      this.getAffiliate = [];
      this.affiliateId = null;
      this.offerId = null;
      this.affiliateOffers = [];
      this.disabledOffer = true;
      this.hiddenBtnUnlink = false;
      this.paramsAttach = {};
      this.pinnedAffiliateId = null;
      this.pinnedAffiliateOfferId = null;
    },
    getAffiliateOffers(id) {
      this.isDataLoading = true;
      return this.$api
        .getAffiliateOffers(id)
        .then((res) => {
          this.affiliateOffers = this.siteId ? res.data.payload.filter(offer => offer.brand_visibility === 'all' || (offer.brand_visibility
              === 'only_selected' && offer.visible_brands.some(brand => brand.site_id === this.siteId))) : res.data.payload;
        })
        .finally(() => {
          this.isDataLoading = false;
        });
    },
    getAffiliatesD(id, limit) {
      this.isDataLoading = true;
      return this.$api
        .getAffiliates({
          search: id,
          limit,
          sort_column: 'email',
          sort_dir: 'asc',
        })
        .then((res) => {
          this.isDataLoading = false;
          this.getAffiliate = res.data.payload;
          this.maxCount = res.data.misc.count;
        })
        .finally(() => {
          this.isDataLoading = false;
        });
    },
    clickConfirmHandler(action) {
      const email = document.querySelector('.wrap-unpin input').value;
      switch (action) {
      case 'attach':
        this.confirm.title = this.$t('players.confirm.title_1');
        this.confirm.msg = `${this.$t('players.confirm.msg_1_1')} <span>${this.data.site_player_id}</span> ${this.$t('players.confirm.msg_1_2')}
                <span>${email}</span>${this.$t('players.confirm.msg_1_3')}`;
        this.confirm.type = 'success';
        this.confirm.action = action;
        this.confirm.actionName = this.$t('players.confirm.action_1');
        this.$refs.confirm.open();
        break;
      case 'detach':
        this.confirm.title = this.$t('players.confirm.title_2');
        this.confirm.msg = `${this.$t('players.confirm.msg_1_1')} <span>${this.data.site_player_id}</span> ${this.$t('players.confirm.msg_2_2')}
                <span>${email}</span>${this.$t('players.confirm.msg_2_3')}`;
        this.confirm.type = 'warning';
        this.confirm.action = action;
        this.confirm.actionName = this.$t('players.confirm.action_2');
        this.$refs.confirm.open();
        break;
      default:
        break;
      }
    },
    changeStatus(action) {
      switch (action) {
      case 'attach':
        this.attach();
        this.$refs.confirm.close();
        break;
      case 'detach':
        this.detach();
        this.$refs.confirm.close();
        break;
      default:
        break;
      }
    },
    attach() {
      this.paramsAttach = {
        affiliate_offer_id: this.offerId,
      };
      this.$api.attachPlayerAffiliate(this.data.id, this.paramsAttach)
        .then(() => {
          this.close('reload');
        });
    },
    detach() {
      this.$api.detachPlayerAffiliate(this.data.id)
        .then(() => {
          this.close('reload');
        });
    },
    close(v) {
      this.$emit('close', v);
    },
  },
};
</script>
