export default {
  mainColor: 'green',
  logoName: 'good-logo.png',
  maxLogoHeight: '24px',
  accentColor: '#2282D6',
  activeColor: '#2282D6',
  dangerColor: '#d26370',
  uiTheme: {
    primaryColor: '#2282D6',
    dangerColor: '#d26370',
    infoColor: '#3b94e3',
    warningColor: '#ffce06',
    lightColor: '#fff',
    darkColor: '#303634',
    // the-header
    headerColor: '#2282D6',
    headerBorder: '#5698d2',
    headerText: '#ffffff',
    headerTextHover: '#ffffff',
    headerTextBorder: '#ffba00',
    reportDropdownBackground: '#1972c0',
    reportDropdownBackgroundHover: '#2282D6',
    reportDropdownActive: '#ffffff',
    reportDropdownActiveHover: '#ffffff',

    reportDropdownTitleColor: '#ffffff',
    reportDropdownTitleBorder: '#ffffff',

    scrollThumb: '#c3c3c3',
    scrollBackground: '#20815e',
    colorFirst: '#1972c0',
    colorSecond: '#e49607',
    backLink: '#2282D6',
  },
};
