export default {
  players: {
    name: 'Игроки',
    menu: {
      playersList: 'Список игроков',
    },
    fields: {
      site_player_id: {
        title: 'ID',
      },
      player_country_name: {
        title: 'Страна',
      },
      phone_number: {
        title: 'Контактный номер',
      },
      affiliate_email: {
        title: 'Партнер',
      },
      is_virtual: {
        title: 'Виртуальный аккаунт',
      },
      email: {
        title: 'Email',
      },
      first_name: {
        title: 'Имя',
      },
      created_at: {
        title: 'Создан',
      },
    },
    nav: {
      info: 'Информация',
      last: 'Последние действия',
    },
    playersList: {
      players: 'Игроки',
      change: 'Изменить',
      res_filter: 'Сбросить фильтры',
      refresh: 'Обновить',
      yes: 'Да',
      no: 'Нет',
      select_filter: 'Пожалуйста, укажите параметры фильтрации для получения интересующего списка игроков или введите ID игрока в поисковой строке.',
      confirm: {
        title: 'Изменить аккаунт',
        to_real: 'на обычный?',
        to_virtuals: 'на виртуальный?',
        msg: 'Это действие не может быть отменено!',
        msg_1: 'Если вы уверены, что хотите изменить аккаунт {player_id} на виртуальный, напечатайте {yes} ниже и нажмите Изменить',
        yes: 'ДА',
        real: 'Обычный?',
        virtual: 'Виртуальный?',
      },
    },
    pinPlayer: {
      pinned: 'Закрепить игрока',
      to_affiliate: 'за партнером',
      top_text_1: 'Выберите партнера и оффер, чтобы закрепить игрока',
      top_text_2: 'Выберите нового партнера и оффер для',
      player: 'игрока',
      or_unpin: 'или открепите текущий',
      aff: 'Партнер',
      aff_req: 'Это поле обязательно',
      placeholder_aff: 'Выбрать партнера',
      unpin: 'Открепить',
      offer: 'Оффер',
      placeholder_offer: 'Выбрать оффер',
      cancel: 'Отменить',
      pin_aff: 'Закрепить партнера',
      save: 'Сохранить',
    },
    confirm: {
      title_1: 'Закрепить игрока за партнером',
      msg_1_1: 'Закрепить игрока:',
      msg_1_2: 'за',
      msg_1_3: '?',
      action_1: 'Закрепить',

      title_2: 'Открепить игрока от партнера',
      msg_2_2: 'от',
      msg_2_3: '?',
      action_2: 'Открепить',

      title_3_1: 'Изменить аккаунт',
      title_3_2: 'на обычный?',
      title_3_3: 'на виртуальный?',
      msg_3_1: 'Вы уверены, что хотите сделать аккаунт',
      msg_3_2: 'обычным?',
      msg_3_3: 'виртуальным?',
      action_3: 'Изменить',

    },
    info: {
      info: 'Информация',
      personal_info: 'Личная информация',
      visit_attributes: 'Атрибуты посещения',
      promo_restrictions: 'Ограничения на промо',
      blocked: 'Заблокирован',
      date_of_birth: 'Дата рождения',
      last_active: 'Последняя активность',
      country: 'Страна',
      create_at: 'Создан',
      verif_account: 'Виртуальный аккаунт',
      aff: 'Партнер',
      edit: 'Редактировать',
      pin: 'Закрепить',
      unpin: 'Открепить',
      payment_method: 'Топ способов оплаты',
      no_data: 'Нет данных',
      top_sport: 'Топ спортивных событий',
      top_leagues: 'Топ спортивных лиг',
      bets: 'Ставки',
      offer: 'Оффер',
      blocked_by_security_service: 'Игрок был заблокирован Службой Безопасности',
      ip: 'IP',
      referer: 'Реферер',
      geo: 'Гео',
      device: 'Устройство',
      os: 'ОС',
      browser: 'Браузер',
      provider: 'Провайдер',
      connection_type: 'Тип соединения',
      language: 'Язык',
      registration_date: 'Дата регистрации',
      registration_type: 'Тип регистрации',
    },
    crm: {
      title: 'История взаимодействия',
      fields: {
        language: {
          title: 'Язык',
        },
        activity_name: {
          title: 'Активность',
        },
        event_timestamp: {
          title: 'Дата',
        },
        type: {
          title: 'Тип',
        },
      },
    },
  },
};
