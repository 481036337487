<template>
  <div
    v-loading="isFirstLoading"
    :style="{ width: getSelectWidth }"
    :class="[
      size,
      {'open': isOpen},
      {'disabled': disabled},
      {'filterable': filterable},
      {'multiple': multiple},
      {'multiple2': typeof(value) !== 'string' && value.length > 1},
      {'labeled': label},
    ]"
    class="ui-select"
  >
    <span
      v-if="label"
      class="label"
      @click="togglePop"
    >
      {{ label }}
    </span>
    <div
      class="control-wrapper"
      @click="togglePop"
    >
      <div class="tags" :class="{ 'limit-tags': !!maxCountTags }">
        <template v-if="placeholder && $_.isNil(selectedOptions[0])">
          <i
            v-if="placeholderIcon"
            class="placeholder ui-m-sm-r"
            :class="placeholderIcon"
          />
          <span class="placeholder">{{ placeholder }}</span>
        </template>
        <template v-else>
          <template v-if="!multiple">
            <div class="value" :class="{'max-width': !allowRemoveValue}">
              <span
                v-if="selectedOptions[0][prefixProp]"
                class="prefix"
                v-html="selectedOptions[0][prefixProp]"
              />
              <span class="label">
                {{ labelI18n ? $t(`${labelI18n}.${selectedOptions[0][nameProp || labelProp]}`) : selectedOptions[0][nameProp || labelProp] }}
              </span>
              <span
                v-if="sufix"
                class="sufix"
                v-html="labelI18n ? $t(`${labelI18n}.${selectedOptions[0][sufixProp]}`) : selectedOptions[0][sufixProp]"
              />
              <ui-icon
                v-if="allowRemoveValue"
                :size="10"
                name="times"
                lib="fa"
                substyle="fas"
                class="del"
                @click.native="removeOption($event, null)"
              />
            </div>
          </template>
          <template v-else>
            <div
              :class="{'with-count': value.length > 0}"
              class="value"
            >
              <template v-if="maxCountTags">
                <div v-for="(_, i) in tagsOptions" :key="i" class="ui-d-flex ui-ai-center">
                  <span class="label">{{ selectedOptions[i] ? labelI18n ? $t(`${labelI18n}.${selectedOptions[i][labelProp]}`) : selectedOptions[i][listLabelProp || labelProp] : '' }}</span>
                  <ui-icon
                    :size="10"
                    name="times"
                    lib="fa"
                    substyle="fas"
                    class="del"
                    @click.native="removeOption($event, selectedOptions[i])"
                  />
                </div>
              </template>
              <template v-else>
                <span class="label">{{ selectedOptions[0] ? labelI18n ? $t(`${labelI18n}.${selectedOptions[0][labelProp]}`) : selectedOptions[0][listLabelProp || labelProp] : '' }}</span>
                <ui-icon
                  :size="10"
                  name="times"
                  lib="fa"
                  substyle="fas"
                  class="del"
                  @click.native="removeOption($event, selectedOptions[0])"
                />
              </template>
            </div>
            <div
              v-show="value.length > (maxCountTags || 1)"
              class="value count"
            >
              <span class="label"> +{{ value.length - (maxCountTags || 1) }}</span>
            </div>
          </template>
        </template>
      </div>
      <i class="dd-icon el-icon-arrow-down" />
    </div>
    <transition name="fade">
      <div
        v-if="!disabled"
        v-show="isOpen"
        class="pop"
      >
        <div class="arrow" />
        <div class="options">
          <ui-input
            v-if="filterable"
            ref="filter"
            v-model="filterQuery"
            :placeholder.sync="filterPlaceholder"
            :tabindex="-1"
            class="filter"
            borderless
            clearable
            autosize
            @input="getOptions"
          />
          <div
            v-loading="remote && isOptionsLoading"
            class="content"
          >
            <div
              ref="list"
              class="items"
            >
              <template v-if="multiple && selectedOptions.length > 0">
                <div class="tags">
                  <div
                    v-for="(option, index) in selectedOptions"
                    :key="index"
                    class="tag"
                  >
                    <span class="label">{{ labelI18n ? $t(`${labelI18n}.${option[labelProp]}`) : option[labelProp] }}</span>
                    <ui-icon
                      :size="10"
                      name="times"
                      lib="fa"
                      substyle="fas"
                      class="del"
                      @click.native="removeOption($event, option)"
                    />
                  </div>
                </div>
              </template>
              <div
                v-for="(option, index) in filterNotSelectedOptions(showingOptions)"
                :key="index"
                :class="{'selected': isOptionSelected(option)}"
                class="item"
                @click="selectOption(option)"
              >
                <span class="text">{{ labelI18n ? $t(`${labelI18n}.${option[labelProp]}`) : option[labelProp] }}</span>
                <span
                  v-if="sufix"
                  class="sufix"
                  v-html="labelI18n ? $t(`${labelI18n}.${option[sufixProp]}`) : option[sufixProp]"
                />
              </div>
            </div>
            <span
              v-show="$_.isEmpty(filterNotSelectedOptions(showingOptions))"
              class="no-opts"
            >{{ noOptsText }}</span>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'UiSelect',
  props: {
    maxCountTags: {
      type: Number,
      default: null,
    },
    sufix: {
      type: Boolean,
      default: false,
    },
    allowRemoveValue: {
      type: Boolean,
      default: true,
    },
    value: {
      type: [String, Number, Array, Object, Boolean],
      default() {
        return [];
      },
    },
    options: {
      type: Array,
      default() {
        return [];
      },
    },
    defaultValue: {
      type: [String, Number, Array, Object],
      default() {
        return [];
      },
    },
    excludeOptions: {
      type: Array,
      default() {
        return [];
      },
    },
    valueProp: {
      type: String,
      default: 'value',
    },
    nameProp: {
      type: String,
      default: '',
    },
    labelProp: {
      type: String,
      default: 'label',
    },
    listLabelProp: {
      type: String,
      default: '',
    },
    sufixProp: {
      type: String,
      default: 'sufix',
    },
    prefixProp: {
      type: [String, Boolean],
      default: false,
    },
    labelI18n: {
      type: String,
      default: '',
    },
    width: {
      type: [String, Number],
      default: 180,
    },
    placeholderIcon: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default() {
        return this.$t('ui.select.default');
      },
    },
    filterPlaceholder: {
      type: String,
      default() {
        return this.$t('ui.select.filter_option');
      },
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    multiTags: {
      type: Boolean,
      default: false,
    },
    tagsLimit: {
      type: Number,
      default: 0,
    },
    filterable: {
      type: Boolean,
      default: false,
    },
    remote: {
      type: Boolean,
      default: false,
    },
    filterMethod: {
      type: Function,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'medium',
    },
    label: {
      type: String,
      default: '',
    },
    remoteAuto: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isOpen: false,
      filterQuery: '',
      isOptionsLoading: false,
      showingOptions: [],
      selectedOptions: [],
      isFirstLoading: false,
    };
  },
  computed: {
    tagsOptions() {
      return new Array(Math.min(this.selectedOptions.length, this.maxCountTags));
    },
    getSelectWidth() {
      if (this.$_.isString(this.width)) {
        return this.width;
      }
      if (this.$_.isNumber(this.width) && this.width > 0) {
        return `${this.width}px`;
      }
      return '100%';
    },
    noOptsText() {
      return this.filterQuery ? this.$t('ui.select.no_match') : this.$t('ui.select.no_options');
    },
  },
  watch: {
    isOpen(nv) {
      if (nv) {
        if (this.filterable) {
          setTimeout(() => {
            this.$refs.filter.focus();
          }, 100);
        }
      }
      setTimeout(() => {
        if (this.$refs.list !== undefined && typeof this.$refs.list.scrollTop === 'number') {
          this.$refs.list.scrollTop = 1;
        }
      }, 300);
    },
    value: {
      deep: true,
      immediate: true,
      handler() {
        this.initSelectedOptions();
      },
    },
    options: {
      deep: true,
      immediate: true,
      handler(nv, ov) {
        if (!this.$_.isEqual(nv, ov)) {
          this.initSelectedOptions();
        }
        if (this.remote) {
          if (this.remoteAuto) this.getOptions();
        } else {
          this.showingOptions = this.$_.clone(this.options);
        }
      },
    },
  },
  mounted() {
    document.addEventListener('click', this.clickOutside);
    this.$el.parentElement.addEventListener('click', this.clickOutside);
    if (!this.remote) {
      this.showingOptions = this.$_.clone(this.options);
    }

    if (this.multiple && this.filterMethod !== null) {
      this.isFirstLoading = true;
    }
  },
  beforeDestroy() {
    document.removeEventListener('click', this.clickOutside);
  },
  methods: {
    initSelectedOptions() {
      if (!this.$_.isEmpty(this.options) || !this.$_.isEmpty(this.showingOptions)) {
        this.selectedOptions = [];
        if (this.value === '') return;
        if (this.multiple) {
          this.value.forEach((val) => {
            this.selectedOptions.push(this.options.find(op => op[this.valueProp] === val)
                || (this.showingOptions && this.showingOptions.find(op => op[this.valueProp] === val))
              || this.getCacheRemoteOption(val));
          });
        } else if (this.showingOptions && this.remote) {
          const showingOptions = this.showingOptions.find(op => op[this.valueProp] === this.value) || [];
          this.selectedOptions.push(showingOptions);
        } else {
          this.selectedOptions.push(this.options.find(opt => opt[this.valueProp] === this.value));
        }
      }
    },
    getOptions() {
      let ops;
      if (this.remote) {
        this.isOptionsLoading = true;
        this.filterMethod(this.filterQuery)
          .then((response) => {
            ops = this.$_.uniqBy([...response, ...this.selectedOptions], this.valueProp);
            if (this.selectedOptions.length > 0 && this.multiple) {
              ops = this.sortOptions(ops);
            }
            this.showingOptions = this.$_.clone(ops);
            this.isOptionsLoading = false;
            this.isFirstLoading = false;
          })
          .catch(() => {
            ops = [];
            this.showingOptions = this.$_.clone(ops);
            this.isOptionsLoading = false;
            this.isFirstLoading = false;
          })
          .finally(() => {
            this.initSelectedOptions();
          });
      } else if (this.filterQuery) {
        if (this.filterMethod) {
          ops = this.filterMethod(this.filterQuery);
        } else {
          ops = this.defaultFilterMethod();
        }
      } else {
        ops = this.options;
      }
      if (this.selectedOptions.length > 0 && this.multiple) {
        ops = this.sortOptions(ops);
      }
      this.showingOptions = this.$_.clone(ops);
    },
    sortOptions(ops) {
      return this.$_.sortBy(ops, [this.labelProp, this.valueProp]);
    },
    filterNotSelectedOptions(ops) {
      let filtered = this.$_.difference(ops, this.selectedOptions);
      filtered = this.$_.differenceWith(filtered, this.excludeOptions, (op, ex) => op[this.valueProp] === ex);
      return filtered;
    },
    clickOutside(e) {
      if (this.disabled) {
        return;
      }
      if (!this.$el.contains(e.target)) {
        this.isOpen = false;
      }
    },
    selectOption(option) {
      if (this.disabled) {
        return;
      }
      let val;
      if (this.multiple) {
        val = this.value === '' ? [] : this.value;
        const i = this.value.indexOf(option[this.valueProp]);
        if (i < 0) {
          val.push(option[this.valueProp]);
          this.selectedOptions.push(option);
        } else {
          this.$_.remove(val, opt => opt === option[this.valueProp]);
        }
        val.sort();
      } else {
        val = option[this.valueProp];
        this.selectedOptions = [option];
        this.togglePop();
      }
      if (this.remote) {
        this.setCacheRemoteOption(option);
      }
      this.initSelectedOptions();
      this.$emit('pick', option);
      this.$emit('select', val);
      this.$emit('input', val);
    },
    isOptionSelected(option) {
      if (this.multiple) {
        return this.value.indexOf(option[this.valueProp]) > -1;
      }
      return this.value === option[this.valueProp];
    },
    defaultFilterMethod() {
      const q = this.filterQuery.toLowerCase();
      return this.options.filter(option => option[this.labelProp].toLowerCase().indexOf(q) > -1 || option[this.valueProp].toString().toLowerCase().indexOf(q) > -1);
    },
    togglePop() {
      if (this.disabled) {
        return;
      }
      this.isOpen = !this.isOpen;
      this.filterQuery = '';

      if (this.isOpen) {
        this.getOptions();
      }
    },
    removeOption(e, option) {
      e.preventDefault();
      e.stopPropagation();
      if (this.disabled) {
        return;
      }
      let val;
      if (this.multiple) {
        val = this.value;
        this.$_.remove(val, opt => opt === option[this.valueProp]);
        if (this.$_.isEmpty(val)) {
          val = this.defaultValue;
        }
      } else {
        val = this.defaultValue || '';
      }
      if (this.remote) {
        this.removeCacheRemoteOption(option);
      }
      this.initSelectedOptions();
      this.$emit('select', val);
      this.$emit('input', val);
    },
    setCacheRemoteOption(option) {
      this.$ls.set(`selectOption/${option[this.valueProp]}`, JSON.stringify(option));
    },
    removeCacheRemoteOption(option) {
      this.$ls.remove(`selectOption/${option[this.valueProp]}`);
    },
    getCacheRemoteOption(value) {
      return JSON.parse(this.$ls.get(`selectOption/${value}`));
    },
  },
};
</script>

<style lang="scss" scoped>
.ui-select{
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: stretch;
  transition: all 0.3s;
  font-size: 13px;

  .label{
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    cursor: pointer;
  }
  .sufix{
    margin-bottom: 4px;
  }
  .control-wrapper{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    box-sizing: border-box;
    border: 1px solid #d3d3d3;
    border-radius: 5px;
    padding: 0 6px 0 8px;
    transition: all 0.3s;
    cursor: pointer;
    background-image: linear-gradient(180deg, #ffffff 0%, #f6f6f6 100%);

    .tags{
      flex-grow: 9;
      display: flex;
      align-items: center;
      height: 30px;
      box-sizing: border-box;

      &.limit-tags {
        max-width: 100%!important;

        .with-count.value {
          max-width: 100%!important;
          justify-content: initial;
          flex-grow: initial;
          padding-left: 0;

          > div {
            padding-left: 8px;
            border-radius: 4px;
            background-color: #eaeaea;
            font-size: 12px;
            color: #303634;

            .ui-icon {
              color: #d26370!important;
              font-size: 12px!important;

              &:hover{
                color: #d26370 !important;
                background-color: transparent;
              }
            }
          }

          div + div {
            margin-left: 4px;
          }
        }
      }

      .placeholder{
        color: #7d7d7d;
      }
      .value{
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-grow: 10;
        height: 24px;
        max-width: 144px;
        padding-left: 8px;
        background-color: transparent;
        border-radius: 3px;
        box-sizing: border-box;
        &.with-count{
          max-width: 104px;
        }
        &.max-width {
          max-width: initial;
        }
        .label{
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .del{
          height: 24px;
          width: 24px;
          border-radius: 3px;
          flex: 0 0 auto;
          display: flex!important;

          &:hover{
            color: white !important;
          }
        }
      }
    }
    .dd-icon{
      display: flex;
      align-items: center;
      justify-content: center;
      color: #7d7d7d;
      font-weight: 400!important;
      font-size: 12px;
      flex: 0 0 auto;
      width: 20px;
      transition: all 0.3s;
    }
  }
  .pop{
    position: absolute;
    z-index: 9999;
    top: 44px;
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 5px;

    &.fade-enter-active, &.fade-leave-active{
      transition: all .4s;
    }
    &.fade-enter, &.fade-leave-to{
      opacity: 0;
      transform: translateY(8px);
    }
    .arrow{
      align-self: center;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid #d3d3d3;
    }
    .options{
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      border: 1px solid #d3d3d3;
      border-radius: 5px;
      .filter{
        border-radius: 5px 5px 0 0;
        overflow: hidden;
        border-bottom: 1px solid #d3d3d3;
      }
      .content{
        display: flex;
        flex-direction: column;
      }
      .items{
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        padding: 4px 0;
        max-height: calc(30px * 5);
        overflow-y: auto;
        .tag{
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-grow: 10;
          height: 24px;
          padding-left: 8px;
          margin: 0 4px;
          margin-bottom: 4px;
          background-color: #efefef;
          border-radius: 3px;
          box-sizing: border-box;
          .label{
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: 500;
            line-height: 12px;
          }
          .del{
            height: 24px;
            width: 24px;
            flex: 0 0 auto;
            border-radius: 3px;
            cursor: pointer;
            &:hover{
              color: white !important;
            }
          }
        }
        .item{
          height: 30px;
          flex: 0 0 auto;
          display: flex;
          align-items: center;
          padding: 0 12px;
          box-sizing: border-box;
          cursor: pointer;
          transition: all 0.3s;
          .text{
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 14px;
          }
          .sufix{
            margin-bottom: 0;
          }
          &.selected{
            background-color: #f5f5f5;
            font-weight: 500;
          }
          &:hover{
            background-color: #f5f7fa;
          }
        }
      }
      .no-opts{
        color: #7d7d7d;
        text-align: center;
        padding: 12px 32px;
      }
    }
  }
  &.disabled{
    cursor: not-allowed;
    filter: grayscale(100%);
    opacity: 0.5;
    .control-wrapper{
      cursor: not-allowed;
    }
  }
  &.multiple{
    .control-wrapper{
      .tags{
        .value{
          max-width: 163px !important;
          &.count{
            justify-content: center;
            padding: 0;
            width: 36px;
            flex: 0 0 auto;
            margin-left: 4px;
            background-color: #d3d3d3;
            .label{
              font-weight: 500;
            }
          }
        }
      }
    }
  }
  &.multiple2{
    .control-wrapper{
      .tags{
        max-width: 158px;
        .value.with-count{
          .label{
            max-width: 90px;
          }
        }
      }
    }
  }
  &.open{
    .control-wrapper{
      .dd-icon{
        transform: rotateX(180deg);
      }
    }
  }
  &.labeled{
    .pop{
      top: 68px;
    }
  }
}
</style>
