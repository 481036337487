import { dependsOnConfig } from '../configs/permissions';

export const generateDependsOnConfig = (requireForConfig) => {
  const requireForConfigKeys = Object.keys(requireForConfig);

  return requireForConfigKeys.reduce((acc, permissionName) => {
    const denyPermissionKeys = requireForConfig[permissionName];

    denyPermissionKeys.forEach((denyPermissionKey) => {
      if (!acc[denyPermissionKey]) acc[denyPermissionKey] = [];
      acc[denyPermissionKey].push(permissionName);
    });

    return acc;
  }, {});
};

export const getPermissionsObject = permissions => permissions.reduce((acc, permission) => {
  const exceptions = [
    'affiliates_sub_affiliates_max_percent',
    'offers_revshare_max_percent_sport',
    'offers_revshare_max_percent_casino',
    'offers_cpa_max_payout',
    'offers_cpa_max_payout_currency_code',
    'offers_cpa_min_payout_deposits_sum_ratio',
    'players_available_properties',
    'players_available_property_groups',
    'reports_affiliates_types',
    'reports_view_affiliates_visibility_teammates_id',
    'media_buying_reports_affiliates_types_view',
    'affiliates_types_view',
    'affiliates_types_edit',
    'affiliates_view_affiliates_visibility_teammates_id',
    'affiliates_edit_affiliates_visibility_teammates_id',

    'crm_segments_view',
    'crm_segments_review',
    'crm_segments_edit',
    'crm_activities_view',
    'crm_activities_review',
    'crm_activities_edit',
    'crm_activities_run',

    'media_buying_posts_view_affiliates_visibility_teammates_id',
    'media_buying_posts_review_affiliates_visibility_teammates_id',
    'media_buying_payments_view_affiliates_visibility_teammates_id',
    'media_buying_payments_review_affiliates_visibility_teammates_id',
    'media_buying_reports_view_affiliates_visibility_teammates_id',

    'media_buying_payments_affiliates_types_view',
    'media_buying_payments_affiliates_types_review',
    'media_buying_payments_moderation_statuses_visible',
    'media_buying_payments_payout_statuses_visible',
    'media_buying_posts_affiliates_types_view',
    'media_buying_posts_affiliates_types_review',
    'media_buying_posts_statuses_visible',
    'media_buying_reports_affiliates_types_view',
  ];

  if (!exceptions.includes(permission)) {
    acc[permission] = 'deny';
  }

  return acc;
}, {});

export const getInheritsObject = (permissions, value = true) => permissions.reduce((acc, permission) => {
  acc[permission] = value;
  return acc;
}, {});

export const getPermissionsSnapshot = (permissions, permissionsArray) => permissionsArray.reduce((acc, permission) => {
  acc[permission] = permissions[permission];

  return acc;
}, {});

export const sortPermissionsSnapshot = (permissionsSnaphot = {}, permissions = {}) => {
  const permissionsSnaphotKeys = Object.keys(permissionsSnaphot);

  return permissionsSnaphotKeys.reduce((acc, permission) => {
    const dependsOn = dependsOnConfig[permission];

    const computedPermissions = { ...permissions, ...acc };

    const notAllowedForDeny = dependsOn.some(dependency => computedPermissions[dependency] === 'deny');

    if (notAllowedForDeny) {
      delete acc[permission];
    }

    return acc;
  }, permissionsSnaphot);
};

export const getInheritsSnapshot = (inherits, inheritsArray) => inheritsArray.reduce((acc, inherit) => {
  acc[inherit] = inherits[inherit];

  return acc;
}, {});

export const sortInheritsSnapshot = (inheritsSnaphot = {}, inherits = {}) => {
  const inheritsSnaphotKeys = Object.keys(inheritsSnaphot);

  return inheritsSnaphotKeys.reduce((acc, inherit) => {
    const dependsOn = dependsOnConfig[inherit];

    const computedInherits = { ...inherits, ...acc };

    const notAllowedForDeny = dependsOn.some(dependency => computedInherits[dependency] === true);

    if (notAllowedForDeny) {
      delete acc[inherit];
    }

    return acc;
  }, inheritsSnaphot);
};

export default {
  generateDependsOnConfig,
  getPermissionsObject,
  getInheritsObject,
  getPermissionsSnapshot,
  sortPermissionsSnapshot,
  getInheritsSnapshot,
  sortInheritsSnapshot,
};
