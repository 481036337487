<template>
  <div v-loading="isLoading" class="team-permissions">
    <el-collapse v-model="activePermissions">
      <el-collapse-item
        :title="$t(`permissions.reports.title`)"
        name="reports"
      >
        <div class="permission-column">
          <div class="card">
            <div class="title">
              <span>{{ $t(`permissions.reports.reports_card_title`) }}</span>
              <span>
                <span class="radio-block radio-block_allow">
                  {{ $t(`permissions.status`) }}
                </span>
              </span>
            </div>
            <div class="card-content">
              <vnodes :vnodes="renderPermissionSwitchControl('reports_view')" />

              <div class="field">
                <el-checkbox-group
                  v-model="pvPermissions.reports_affiliates_types"
                  :disabled="pvPermissions.reports_view === 'deny'"
                >
                  <div class="permission-column">
                    <el-checkbox
                      label="independent"
                      class="checkbox"
                    >
                      {{ $t(`permissions.independent`) }}
                    </el-checkbox>
                    <el-checkbox
                      label="own_affiliates"
                      class="checkbox"
                    >
                      {{ $t(`permissions.own_affiliates`) }}
                    </el-checkbox>
                  </div>
                  <div class="permission-column">
                    <el-checkbox
                      label="other_teams_affiliates"
                      class="checkbox"
                    >
                      {{ $t(`permissions.other_teams_affiliates`) }}
                    </el-checkbox>
                  </div>
                </el-checkbox-group>
                <permission-tree
                  :disabled="pvPermissions.reports_view === 'deny'"
                  :users-list="usersList"
                  :affiliates-types="pvPermissions.reports_affiliates_types"
                  :teammates-ids.sync="pvPermissions.reports_view_affiliates_visibility_teammates_id"
                />
              </div>
            </div>
          </div>
          <div class="card">
            <div class="title">
              <span>{{ $t(`permissions.reports.reports_group_by`) }}</span>
              <span>
                <span class="radio-block radio-block_allow">
                  {{ $t(`permissions.status`) }}
                </span>
              </span>
            </div>
            <div class="card-content">
              <vnodes :vnodes="renderPermissionSwitchControl('reports_group_by_affiliates')" />

              <vnodes :vnodes="renderPermissionSwitchControl('reports_group_by_countries')" />

              <vnodes :vnodes="renderPermissionSwitchControl('reports_group_by_sub_affiliates')" />

              <vnodes :vnodes="renderPermissionSwitchControl('reports_group_by_payments')" />
            </div>
          </div>
        </div>
        <div class="permission-column">
          <div class="card">
            <div class="title">
              <span>{{ $t(`permissions.reports.reports_view_columns`) }}</span>
              <span>
                <span class="radio-block radio-block_allow">
                  {{ $t(`permissions.status`) }}
                </span>
              </span>
            </div>
            <div class="card-content">
              <vnodes :vnodes="renderPermissionSwitchControl('reports_view_revenue_columns')" />

              <vnodes :vnodes="renderPermissionSwitchControl('reports_view_profit_columns')" />

              <vnodes :vnodes="renderPermissionSwitchControl('reports_view_deposits_sum_columns')" />

              <vnodes :vnodes="renderPermissionSwitchControl('reports_view_deposits_count_columns')" />

              <vnodes :vnodes="renderPermissionSwitchControl('reports_view_withdrawals_sum_columns')" />

              <vnodes :vnodes="renderPermissionSwitchControl('reports_view_withdrawals_count_columns')" />

              <vnodes :vnodes="renderPermissionSwitchControl('reports_view_sport_bets_sum_columns')" />

              <vnodes :vnodes="renderPermissionSwitchControl('reports_view_sport_bets_count_columns')" />

              <vnodes :vnodes="renderPermissionSwitchControl('reports_view_casino_bets_sum_columns')" />

              <vnodes :vnodes="renderPermissionSwitchControl('reports_view_casino_bets_count_columns')" />
            </div>
          </div>
        </div>
      </el-collapse-item>
      <el-collapse-item
        :title="$t(`permissions.affiliates.title`)"
        name="affiliates"
      >
        <div class="permission-column">
          <div class="card">
            <div class="title">
              <span>{{ $t(`permissions.affiliates.affiliates_card_title`) }}</span>
              <span>
                <span class="radio-block radio-block_allow">
                  {{ $t(`permissions.status`) }}
                </span>
              </span>
            </div>
            <div class="card-content">
              <vnodes :vnodes="renderPermissionSwitchControl('affiliates_view')" />

              <div class="field">
                <el-checkbox-group
                  v-model="pvPermissions.affiliates_types_view"
                  :disabled="pvPermissions.affiliates_view === 'deny'"
                >
                  <div class="permission-column">
                    <el-checkbox
                      label="independent"
                      class="checkbox"
                    >
                      {{ $t(`permissions.independent`) }}
                    </el-checkbox>
                    <el-checkbox
                      label="own_affiliates"
                      class="checkbox"
                    >
                      {{ $t(`permissions.own_affiliates`) }}
                    </el-checkbox>
                  </div>
                  <div class="permission-column">
                    <el-checkbox
                      label="other_teams_affiliates"
                      class="checkbox"
                    >
                      {{ $t(`permissions.other_teams_affiliates`) }}
                    </el-checkbox>
                  </div>
                </el-checkbox-group>
                <permission-tree
                  :disabled="pvPermissions.affiliates_view === 'deny'"
                  :users-list="usersList"
                  :affiliates-types="pvPermissions.affiliates_types_view"
                  :teammates-ids.sync="pvPermissions.affiliates_view_affiliates_visibility_teammates_id"
                />
              </div>

              <vnodes :vnodes="renderPermissionSwitchControl('affiliates_edit')" />

              <div class="field">
                <el-checkbox-group
                  v-model="pvPermissions.affiliates_types_edit"
                  :disabled="pvPermissions.affiliates_edit === 'deny' || pvPermissions.affiliates_view === 'deny'"
                >
                  <div class="permission-column">
                    <el-checkbox
                      label="independent"
                      class="checkbox"
                    >
                      {{ $t(`permissions.independent`) }}
                    </el-checkbox>
                    <el-checkbox
                      label="own_affiliates"
                      class="checkbox"
                    >
                      {{ $t(`permissions.own_affiliates`) }}
                    </el-checkbox>
                  </div>
                  <div class="permission-column">
                    <el-checkbox
                      label="other_teams_affiliates"
                      class="checkbox"
                    >
                      {{ $t(`permissions.other_teams_affiliates`) }}
                    </el-checkbox>
                  </div>
                </el-checkbox-group>
                <permission-tree
                  :disabled="pvPermissions.affiliates_edit === 'deny' || pvPermissions.affiliates_view === 'deny'"
                  :users-list="usersList"
                  :affiliates-types="pvPermissions.affiliates_types_edit"
                  :teammates-ids.sync="pvPermissions.affiliates_edit_affiliates_visibility_teammates_id"
                />
              </div>
            </div>
          </div>
          <div class="card">
            <div class="title">
              <span>{{ $t(`permissions.affiliates.affiliates_sub_affiliates`) }}</span>
              <span>
                <span class="radio-block radio-block_allow">
                  {{ $t(`permissions.status`) }}
                </span>
              </span>
            </div>
            <div class="card-content">
              <vnodes :vnodes="renderPermissionSwitchControl('affiliates_sub_affiliates_edit_settings')" />

              <div class="field field-input">
                <span class="label">{{ $t(`permissions.affiliates.affiliates_sub_affiliates_max_percent`) }}</span>
                <div class="values ">
                  <ui-input
                    v-model="pvPermissions.affiliates_sub_affiliates_max_percent"
                    :disabled="pvPermissions.affiliates_view === 'deny' || pvPermissions.affiliates_sub_affiliates_edit_settings === 'deny' || pvPermissions.affiliates_edit === 'deny'"
                    :error="errors.affiliates_sub_affiliates_max_percent"
                    error-tooltip
                    size="medium"
                    class=""
                    width="80px"
                    post-icon="percent"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="title">
              <span>{{ $t(`permissions.affiliates.affiliates_actions_title`) }}</span>
              <span>
                <span class="radio-block radio-block_allow">
                  {{ $t(`permissions.status`) }}
                </span>
              </span>
            </div>
            <div class="card-content">
              <vnodes :vnodes="renderPermissionSwitchControl('affiliates_activity_log_view')" />

              <vnodes :vnodes="renderPermissionSwitchControl('affiliates_can_login')" />

              <vnodes :vnodes="renderPermissionSwitchControl('affiliates_payment_info_edit')" />

              <vnodes :vnodes="renderPermissionSwitchControl('affiliates_domain_change_notifications_edit')" />

              <vnodes :vnodes="renderPermissionSwitchControl('affiliates_reporting_settings_rs_profit_visibility_edit')" />

              <vnodes :vnodes="renderPermissionSwitchControl('affiliates_reporting_settings_cpa_profit_visibility_edit')" />

              <vnodes :vnodes="renderPermissionSwitchControl('affiliates_email_edit')" />

              <vnodes :vnodes="renderPermissionSwitchControl('affiliates_payments_can_create')" />

              <vnodes :vnodes="renderPermissionSwitchControl('affiliates_media_buying_edit')" />

              <vnodes :vnodes="renderPermissionSwitchControl('affiliates_promo_codes_edit')" />

              <vnodes :vnodes="renderPermissionSwitchControl('affiliates_balance_edit')" />

              <vnodes :vnodes="renderPermissionSwitchControl('affiliates_rotation_groups_edit')" />

              <vnodes :vnodes="renderPermissionSwitchControl('affiliates_limits_edit')" />
            </div>
          </div>
        </div>
        <div class="permission-column">
          <div class="card">
            <div class="title">
              <span>{{ $t(`permissions.offers.offers_card_title`) }}</span>
              <span>
                <span class="radio-block radio-block_allow">
                  {{ $t(`permissions.status`) }}
                </span>
              </span>
            </div>
            <div class="card-content">
              <vnodes :vnodes="renderPermissionSwitchControl('offers_edit')" />
            </div>
            <div class="card-content">
              <vnodes :vnodes="renderPermissionSwitchControl('offers_delete')" />
            </div>
          </div>
          <div class="card">
            <div class="title">
              <span>{{ $t(`permissions.offers.offers_revshare`) }}</span>
              <span>
                <span class="radio-block radio-block_allow">
                  {{ $t(`permissions.status`) }}
                </span>
              </span>
            </div>
            <div class="card-content">
              <vnodes :vnodes="renderPermissionSwitchControl('offers_revshare_edit_settings')" />

              <div class="field field-input">
                <span class="label">{{ $t(`permissions.offers.offers_revshare_max_percent_sport`) }}</span>
                <div class="values ">
                  <ui-input
                    v-model="pvPermissions.offers_revshare_max_percent_sport"
                    :disabled="pvPermissions.affiliates_view === 'deny' || pvPermissions.affiliates_edit === 'deny' || pvPermissions.offers_edit === 'deny' || pvPermissions.offers_revshare_edit_settings === 'deny'"
                    :error="errors.offers_revshare_max_percent_sport"
                    error-tooltip
                    size="medium"
                    class=""
                    width="80px"
                    post-icon="percent"
                  />
                </div>
              </div>
              <div class="field field-input">
                <span class="label">{{ $t(`permissions.offers.offers_revshare_max_percent_casino`) }}</span>
                <div class="values ">
                  <ui-input
                    v-model="pvPermissions.offers_revshare_max_percent_casino"
                    :disabled="pvPermissions.affiliates_view === 'deny' || pvPermissions.affiliates_edit === 'deny' || pvPermissions.offers_edit === 'deny' || pvPermissions.offers_revshare_edit_settings === 'deny'"
                    :error="errors.offers_revshare_max_percent_casino"
                    error-tooltip
                    size="medium"
                    class=""
                    width="80px"
                    post-icon="percent"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="title">
              <span>{{ $t(`permissions.offers.offers_cpa`) }}</span>
              <span>
                <span class="radio-block radio-block_allow">
                  {{ $t(`permissions.status`) }}
                </span>
              </span>
            </div>
            <div class="card-content">
              <vnodes :vnodes="renderPermissionSwitchControl('offers_cpa_edit_settings')" />
              <div class="field field-input select">
                <span class="label">{{ $t(`permissions.offers.offers_cpa_max_payout_currency`) }}</span>
                <div class="values">
                  <el-select
                    v-model="pvPermissions.offers_cpa_max_payout_currency_code"
                    :placeholder="$t('permissions.offers.not_selected')"
                    class="select"
                    filterable
                    size="small"
                    :disabled="pvPermissions.affiliates_view === 'deny' || pvPermissions.affiliates_edit === 'deny' || pvPermissions.offers_edit === 'deny' || pvPermissions.offers_cpa_edit_settings === 'deny'"
                    :class="{ error: getError('offers_cpa_max_payout_currency_code') }"
                  >
                    <el-option
                      v-for="item in currencyOptions"
                      :key="item.code"
                      :label="item.name"
                      :value="item.code"
                    />
                  </el-select>
                  <div v-if="getError('offers_cpa_max_payout_currency_code')" class="field-error">
                    {{ getError('offers_cpa_max_payout_currency_code') }}
                  </div>
                </div>
              </div>
              <div class="field field-input">
                <span class="label">{{ $t(`permissions.offers.offers_cpa_max_payout`) }}</span>
                <div class="values ">
                  <ui-input
                    v-model="pvPermissions.offers_cpa_max_payout"
                    :disabled="pvPermissions.affiliates_view === 'deny' || pvPermissions.affiliates_edit === 'deny' || pvPermissions.offers_edit === 'deny' || pvPermissions.offers_cpa_edit_settings === 'deny'"
                    size="medium"
                    class=""
                    :error="getError('offers_cpa_max_payout')"
                    error-tooltip
                    width="80px"
                  />
                </div>
              </div>
              <div class="field field-input">
                <span class="label">{{ $t(`permissions.offers.offers_cpa_min_payout_deposits_sum_ratio`) }}</span>
                <div class="values ">
                  <ui-input
                    v-model="pvPermissions.offers_cpa_min_payout_deposits_sum_ratio"
                    :disabled="pvPermissions.affiliates_view === 'deny' || pvPermissions.affiliates_edit === 'deny' || pvPermissions.offers_edit === 'deny' || pvPermissions.offers_cpa_edit_settings === 'deny'"
                    size="medium"
                    :error="getError('offers_cpa_min_payout_deposits_sum_ratio')"
                    error-tooltip
                    class=""
                    width="80px"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="title">
              <span>{{ $t(`permissions.affiliates.affiliates_notes`) }}</span>
              <span>
                <span class="radio-block radio-block_allow">
                  {{ $t(`permissions.status`) }}
                </span>
              </span>
            </div>
            <div class="card-content">
              <vnodes :vnodes="renderPermissionSwitchControl('affiliates_notes_view')" />

              <vnodes :vnodes="renderPermissionSwitchControl('affiliates_notes_edit')" />
            </div>
          </div>

          <div class="card">
            <div class="title">
              <span>{{ $t(`permissions.affiliates.affiliates_security_title`) }}</span>
              <span>
                <span class="radio-block radio-block_allow">
                  {{ $t(`permissions.status`) }}
                </span>
              </span>
            </div>
            <div class="card-content">
              <vnodes :vnodes="renderPermissionSwitchControl('affiliates_password_edit')" />

              <vnodes :vnodes="renderPermissionSwitchControl('affiliates_2fa_can_reset')" />
            </div>
          </div>
          <div class="card">
            <div class="title">
              <span>{{ $t(`permissions.affiliates.affiliates_status_change_title`) }}</span>
              <span>
                <span class="radio-block radio-block_allow">
                  {{ $t(`permissions.status`) }}
                </span>
              </span>
            </div>
            <div class="card-content">
              <vnodes :vnodes="renderPermissionSwitchControl('affiliates_can_approve')" />

              <vnodes :vnodes="renderPermissionSwitchControl('affiliates_can_decline')" />

              <vnodes :vnodes="renderPermissionSwitchControl('affiliates_can_block')" />

              <vnodes :vnodes="renderPermissionSwitchControl('affiliates_can_unblock')" />

              <vnodes :vnodes="renderPermissionSwitchControl('affiliates_can_revoke')" />
            </div>
          </div>
          <div class="card">
            <div class="title">
              <span>{{ $t(`permissions.affiliates.affiliates_notifications`) }}</span>
              <span>
                <span class="radio-block radio-block_allow">
                  {{ $t(`permissions.status`) }}
                </span>
              </span>
            </div>
            <div class="card-content">
              <vnodes :vnodes="renderPermissionSwitchControl('affiliates_notifications_view')" />

              <vnodes :vnodes="renderPermissionSwitchControl('affiliates_notifications_edit')" />
            </div>
          </div>
        </div>
      </el-collapse-item>
      <el-collapse-item
        :title="$t(`permissions.payments.title`)"
        name="payments"
      >
        <div class="permission-column">
          <div class="card">
            <div class="title">
              <span>{{ $t(`permissions.payments.payments_card_title`) }}</span>
              <span>
                <span class="radio-block radio-block_allow">
                  {{ $t(`permissions.status`) }}
                </span>
              </span>
            </div>
            <div class="card-content">
              <vnodes :vnodes="renderPermissionSwitchControl('payments_view')" />

              <vnodes :vnodes="renderPermissionSwitchControl('payments_review_actions')" />

              <vnodes :vnodes="renderPermissionSwitchControl('payments_chargebacks_actions')" />
            </div>
          </div>
        </div>
      </el-collapse-item>
      <el-collapse-item
        :title="$t(`permissions.players.title`)"
        name="players"
      >
        <div class="permission-column">
          <div class="card">
            <div class="title">
              <span>{{ $t(`permissions.players.players_card_title`) }}</span>
              <span>
                <span class="radio-block radio-block_allow">
                  {{ $t(`permissions.status`) }}
                </span>
              </span>
            </div>
            <div class="card-content">
              <vnodes :vnodes="renderPermissionSwitchControl('players_view')" />

              <div class="field">
                <el-checkbox-group
                  v-model="pvPermissions.players_available_property_groups"
                  :disabled="pvPermissions.players_view === 'deny'"
                >
                  <div class="permission-column">
                    <el-checkbox
                      label="sub_id"
                      class="checkbox"
                    >
                      {{ $t('permissions.players.subId') }}
                    </el-checkbox>
                    <el-checkbox
                      label="personal_information"
                      class="checkbox"
                    >
                      {{ $t('permissions.players.personal_information') }}
                    </el-checkbox>
                    <el-checkbox
                      label="contacts"
                      class="checkbox"
                    >
                      {{ $t('permissions.players.contacts') }}
                    </el-checkbox>
                  </div>
                  <div class="permission-column">
                    <el-checkbox
                      label="country"
                      class="checkbox"
                    >
                      {{ $t('permissions.players.country') }}
                    </el-checkbox>
                    <el-checkbox
                      label="affiliate"
                      class="checkbox"
                    >
                      {{ $t('permissions.players.affiliate') }}
                    </el-checkbox>
                    <el-checkbox
                      label="visit_information"
                      class="checkbox"
                    >
                      {{ $t('permissions.players.visit') }}
                    </el-checkbox>
                  </div>
                </el-checkbox-group>
              </div>
            </div>
          </div>
        </div>
        <div class="permission-column">
          <div class="card">
            <div class="title">
              <span>{{ $t(`permissions.players.players_card_other`) }}</span>
              <span>
                <span class="radio-block radio-block_allow">
                  {{ $t(`permissions.status`) }}
                </span>
              </span>
            </div>
            <div class="card-content">
              <vnodes :vnodes="renderPermissionSwitchControl('players_virtual_edit_settings')" />

              <vnodes :vnodes="renderPermissionSwitchControl('players_assign_affiliate')" />
            </div>
          </div>
        </div>
      </el-collapse-item>
      <el-collapse-item
        :title="$t(`permissions.dms.title`)"
        name="dms"
      >
        <div class="permission-column">
          <div class="card">
            <div class="title">
              <span>{{ $t(`permissions.dms.dns_hosting_accounts`) }}</span>
              <span>
                <span class="radio-block radio-block_allow">
                  {{ $t(`permissions.status`) }}
                </span>
              </span>
            </div>
            <div class="card-content">
              <vnodes :vnodes="renderPermissionSwitchControl('dns_hosting_accounts_view')" />

              <vnodes :vnodes="renderPermissionSwitchControl('dns_hosting_accounts_edit')" />
            </div>
          </div>
          <div class="card">
            <div class="title">
              <span>{{ $t(`permissions.dms.domain_registrar_accounts`) }}</span>
              <span>
                <span class="radio-block radio-block_allow">
                  {{ $t(`permissions.status`) }}
                </span>
              </span>
            </div>
            <div class="card-content">
              <vnodes :vnodes="renderPermissionSwitchControl('domain_registrar_accounts_view')" />

              <vnodes :vnodes="renderPermissionSwitchControl('domain_registrar_accounts_edit')" />
            </div>
          </div>
          <div class="card">
            <div class="title">
              <span>{{ $t(`permissions.dms.platform_servers`) }}</span>
              <span>
                <span class="radio-block radio-block_allow">
                  {{ $t(`permissions.status`) }}
                </span>
              </span>
            </div>
            <div class="card-content">
              <vnodes :vnodes="renderPermissionSwitchControl('platform_servers_view')" />

              <vnodes :vnodes="renderPermissionSwitchControl('platform_servers_edit')" />

              <vnodes :vnodes="renderPermissionSwitchControl('platform_servers_can_run_jobs')" />
            </div>
          </div>
        </div>
        <div class="permission-column">
          <div class="card">
            <div class="title">
              <span>{{ $t(`permissions.dms.platform_service_domains`) }}</span>
              <span>
                <span class="radio-block radio-block_allow">
                  {{ $t(`permissions.status`) }}
                </span>
              </span>
            </div>
            <div class="card-content">
              <vnodes :vnodes="renderPermissionSwitchControl('platform_service_domains_view')" />

              <vnodes :vnodes="renderPermissionSwitchControl('platform_service_domains_edit')" />

              <vnodes :vnodes="renderPermissionSwitchControl('platform_service_domains_can_run_jobs')" />
            </div>
          </div>
          <div class="card">
            <div class="title">
              <span>{{ $t(`permissions.dms.service_rotation_groups`) }}</span>
              <span>
                <span class="radio-block radio-block_allow">
                  {{ $t(`permissions.status`) }}
                </span>
              </span>
            </div>
            <div class="card-content">
              <vnodes :vnodes="renderPermissionSwitchControl('service_rotation_groups_view')" />

              <vnodes :vnodes="renderPermissionSwitchControl('service_rotation_groups_edit')" />

              <vnodes :vnodes="renderPermissionSwitchControl('service_rotation_groups_domains_view')" />

              <vnodes :vnodes="renderPermissionSwitchControl('service_rotation_groups_domains_edit')" />
            </div>
          </div>
          <div class="card">
            <div class="title">
              <span>{{ $t(`permissions.dms.dedicated_site_domains`) }}</span>
              <span>
                <span class="radio-block radio-block_allow">
                  {{ $t(`permissions.status`) }}
                </span>
              </span>
            </div>
            <div class="card-content">
              <vnodes :vnodes="renderPermissionSwitchControl('platform_betting_domains_view')" />

              <vnodes :vnodes="renderPermissionSwitchControl('platform_betting_domains_edit')" />
            </div>
          </div>
        </div>
      </el-collapse-item>
      <el-collapse-item
        :title="$t(`permissions.media_buying.title`)"
        name="media-buying"
      >
        <div class="permission-column">
          <div class="card">
            <div class="title">
              <span>{{ $t(`permissions.media_buying.payments`) }}</span>
              <span>
                <span class="radio-block radio-block_allow">
                  {{ $t(`permissions.status`) }}
                </span>
              </span>
            </div>
            <div class="card-content">
              <vnodes :vnodes="renderPermissionSwitchControl('media_buying_payments_view')" />
              <div class="field">
                <el-checkbox-group
                  v-model="pvPermissions.media_buying_payments_affiliates_types_view"
                  :disabled="pvPermissions.media_buying_payments_view === 'deny'"
                >
                  <div class="permission-column">
                    <el-checkbox
                      label="independent"
                      class="checkbox"
                    >
                      {{ $t(`permissions.independent`) }}
                    </el-checkbox>
                    <el-checkbox
                      label="own_affiliates"
                      class="checkbox"
                    >
                      {{ $t(`permissions.own_affiliates`) }}
                    </el-checkbox>
                  </div>
                  <div class="permission-column">
                    <el-checkbox
                      label="other_teams_affiliates"
                      class="checkbox"
                    >
                      {{ $t(`permissions.other_teams_affiliates`) }}
                    </el-checkbox>
                  </div>
                </el-checkbox-group>
                <permission-tree
                  :disabled="pvPermissions.media_buying_payments_view === 'deny'"
                  :users-list="usersList"
                  :affiliates-types="pvPermissions.media_buying_payments_affiliates_types_view"
                  :teammates-ids.sync="pvPermissions.media_buying_payments_view_affiliates_visibility_teammates_id"
                />
              </div>
            </div>
            <div class="card-content">
              <vnodes :vnodes="renderPermissionSwitchControl('media_buying_payments_review')" />

              <div class="field">
                <el-checkbox-group
                  v-model="pvPermissions.media_buying_payments_affiliates_types_review"
                  :disabled="pvPermissions.media_buying_payments_review === 'deny' || pvPermissions.media_buying_payments_view === 'deny'"
                >
                  <div class="permission-column">
                    <el-checkbox
                      label="independent"
                      class="checkbox"
                    >
                      {{ $t(`permissions.independent`) }}
                    </el-checkbox>
                    <el-checkbox
                      label="own_affiliates"
                      class="checkbox"
                    >
                      {{ $t(`permissions.own_affiliates`) }}
                    </el-checkbox>
                  </div>
                  <div class="permission-column">
                    <el-checkbox
                      label="other_teams_affiliates"
                      class="checkbox"
                    >
                      {{ $t(`permissions.other_teams_affiliates`) }}
                    </el-checkbox>
                  </div>
                </el-checkbox-group>
                <permission-tree
                  :disabled="pvPermissions.media_buying_payments_review === 'deny' || pvPermissions.media_buying_payments_view === 'deny'"
                  :users-list="usersList"
                  :affiliates-types="pvPermissions.media_buying_payments_affiliates_types_review"
                  :teammates-ids.sync="pvPermissions.media_buying_payments_review_affiliates_visibility_teammates_id"
                />
              </div>
            </div>
          </div>
          <div class="card">
            <div class="title">
              <span>{{ $t(`permissions.media_buying.payments_moderation`) }}</span>
              <span>
                <span class="radio-block radio-block_allow">
                  {{ $t(`permissions.status`) }}
                </span>
              </span>
            </div>
            <div class="card-content">
              <div class="field">
                <span class="label">{{ $t(`permissions.media_buying.statuses`) }}</span>
              </div>
              <div class="field">
                <el-checkbox-group
                  v-model="pvPermissions.media_buying_payments_moderation_statuses_visible"
                  :disabled="pvPermissions.media_buying_payments_view === 'deny'"
                >
                  <div class="permission-column">
                    <el-checkbox
                      label="on_review"
                      class="checkbox"
                    >
                      {{ $t(`permissions.on_review`) }}
                    </el-checkbox>
                    <el-checkbox
                      label="for_rework"
                      class="checkbox"
                    >
                      {{ $t(`permissions.for_rework`) }}
                    </el-checkbox>
                  </div>
                  <div class="permission-column">
                    <el-checkbox
                      label="approved"
                      class="checkbox"
                    >
                      {{ $t(`permissions.approved`) }}
                    </el-checkbox>
                    <el-checkbox
                      label="declined"
                      class="checkbox"
                    >
                      {{ $t(`permissions.declined`) }}
                    </el-checkbox>
                  </div>
                </el-checkbox-group>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="title">
              <span>{{ $t(`permissions.media_buying.payments_payout`) }}</span>
              <span>
                <span class="radio-block radio-block_allow">
                  {{ $t(`permissions.status`) }}
                </span>
              </span>
            </div>
            <div class="card-content">
              <div class="field">
                <span class="label">{{ $t(`permissions.media_buying.statuses`) }}</span>
              </div>
              <div class="field">
                <el-checkbox-group
                  v-model="pvPermissions.media_buying_payments_payout_statuses_visible"
                  :disabled="pvPermissions.media_buying_payments_view === 'deny'"
                >
                  <div class="permission-column">
                    <el-checkbox
                      label="not_paid"
                      class="checkbox"
                    >
                      {{ $t(`permissions.not_paid`) }}
                    </el-checkbox>
                    <el-checkbox
                      label="for_rework"
                      class="checkbox"
                    >
                      {{ $t(`permissions.for_rework`) }}
                    </el-checkbox>
                    <el-checkbox
                      label="processing"
                      class="checkbox"
                    >
                      {{ $t(`permissions.processing`) }}
                    </el-checkbox>
                  </div>
                  <div class="permission-column">
                    <el-checkbox
                      label="paid"
                      class="checkbox"
                    >
                      {{ $t(`permissions.paid`) }}
                    </el-checkbox>
                    <el-checkbox
                      label="failed"
                      class="checkbox"
                    >
                      {{ $t(`permissions.failed`) }}
                    </el-checkbox>
                  </div>
                </el-checkbox-group>
              </div>
            </div>
          </div>
        </div>
        <div class="permission-column">
          <div class="card">
            <div class="title">
              <span>{{ $t(`permissions.media_buying.posts`) }}</span>
              <span>
                <span class="radio-block radio-block_allow">
                  {{ $t(`permissions.status`) }}
                </span>
              </span>
            </div>
            <div class="card-content">
              <vnodes :vnodes="renderPermissionSwitchControl('media_buying_posts_view')" />

              <div class="field">
                <el-checkbox-group
                  v-model="pvPermissions.media_buying_posts_affiliates_types_view"
                  :disabled="pvPermissions.media_buying_posts_view === 'deny'"
                >
                  <div class="permission-column">
                    <el-checkbox
                      label="independent"
                      class="checkbox"
                    >
                      {{ $t(`permissions.independent`) }}
                    </el-checkbox>
                    <el-checkbox
                      label="own_affiliates"
                      class="checkbox"
                    >
                      {{ $t(`permissions.own_affiliates`) }}
                    </el-checkbox>
                  </div>
                  <div class="permission-column">
                    <el-checkbox
                      label="other_teams_affiliates"
                      class="checkbox"
                    >
                      {{ $t(`permissions.other_teams_affiliates`) }}
                    </el-checkbox>
                  </div>
                </el-checkbox-group>
                <permission-tree
                  :disabled="pvPermissions.media_buying_posts_view === 'deny'"
                  :users-list="usersList"
                  :affiliates-types="pvPermissions.media_buying_posts_affiliates_types_view"
                  :teammates-ids.sync="pvPermissions.media_buying_posts_view_affiliates_visibility_teammates_id"
                />
              </div>
            </div>
            <div class="card-content">
              <vnodes :vnodes="renderPermissionSwitchControl('media_buying_posts_review')" />

              <div class="field">
                <el-checkbox-group
                  v-model="pvPermissions.media_buying_posts_affiliates_types_review"
                  :disabled="pvPermissions.media_buying_posts_view === 'deny' || pvPermissions.media_buying_posts_review === 'deny'"
                >
                  <div class="permission-column">
                    <el-checkbox
                      label="independent"
                      class="checkbox"
                    >
                      {{ $t(`permissions.independent`) }}
                    </el-checkbox>
                    <el-checkbox
                      label="own_affiliates"
                      class="checkbox"
                    >
                      {{ $t(`permissions.own_affiliates`) }}
                    </el-checkbox>
                  </div>
                  <div class="permission-column">
                    <el-checkbox
                      label="other_teams_affiliates"
                      class="checkbox"
                    >
                      {{ $t(`permissions.other_teams_affiliates`) }}
                    </el-checkbox>
                  </div>
                </el-checkbox-group>
                <permission-tree
                  :disabled="pvPermissions.media_buying_posts_view === 'deny' || pvPermissions.media_buying_posts_review === 'deny'"
                  :users-list="usersList"
                  :affiliates-types="pvPermissions.media_buying_posts_affiliates_types_review"
                  :teammates-ids.sync="pvPermissions.media_buying_posts_review_affiliates_visibility_teammates_id"
                />
              </div>
            </div>
            <div class="card-content">
              <div class="field">
                <span class="label">{{ $t(`permissions.media_buying.statuses`) }}</span>
              </div>
              <div class="field">
                <el-checkbox-group
                  v-model="pvPermissions.media_buying_posts_statuses_visible"
                  :disabled="pvPermissions.media_buying_posts_view === 'deny'"
                >
                  <div class="permission-column">
                    <el-checkbox
                      label="on_review"
                      class="checkbox"
                    >
                      {{ $t(`permissions.on_review`) }}
                    </el-checkbox>
                    <el-checkbox
                      label="for_rework"
                      class="checkbox"
                    >
                      {{ $t(`permissions.for_rework`) }}
                    </el-checkbox>
                  </div>
                  <div class="permission-column">
                    <el-checkbox
                      label="approved"
                      class="checkbox"
                    >
                      {{ $t(`permissions.approved`) }}
                    </el-checkbox>
                    <el-checkbox
                      label="declined"
                      class="checkbox"
                    >
                      {{ $t(`permissions.declined`) }}
                    </el-checkbox>
                  </div>
                </el-checkbox-group>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="title">
              <span>{{ $t(`permissions.reports.reports_card_title`) }}</span>
              <span>
                <span class="radio-block radio-block_allow">
                  {{ $t(`permissions.status`) }}
                </span>
              </span>
            </div>
            <div class="card-content">
              <vnodes :vnodes="renderPermissionSwitchControl('media_buying_reports_view')" />

              <div class="field">
                <el-checkbox-group
                  v-model="pvPermissions.media_buying_reports_affiliates_types_view"
                  :disabled="pvPermissions.media_buying_reports_view === 'deny'"
                >
                  <div class="permission-column">
                    <el-checkbox
                      label="independent"
                      class="checkbox"
                    >
                      {{ $t(`permissions.independent`) }}
                    </el-checkbox>
                    <el-checkbox
                      label="own_affiliates"
                      class="checkbox"
                    >
                      {{ $t(`permissions.own_affiliates`) }}
                    </el-checkbox>
                  </div>
                  <div class="permission-column">
                    <el-checkbox
                      label="other_teams_affiliates"
                      class="checkbox"
                    >
                      {{ $t(`permissions.other_teams_affiliates`) }}
                    </el-checkbox>
                  </div>
                </el-checkbox-group>
                <permission-tree
                  :disabled="pvPermissions.media_buying_reports_view === 'deny'"
                  :users-list="usersList"
                  :affiliates-types="pvPermissions.media_buying_reports_affiliates_types_view"
                  :teammates-ids.sync="pvPermissions.media_buying_reports_view_affiliates_visibility_teammates_id"
                />
              </div>
            </div>
          </div>
        </div>
      </el-collapse-item>
      <el-collapse-item
        :title="$t(`permissions.crm.title`)"
        name="crm"
      >
        <div class="permission-column">
          <div class="card">
            <div class="title">
              <span>{{ $t(`permissions.crm.segments`) }}</span>
            </div>
            <div class="card-content">
              <div class="field">
                <span class="label">{{ $t(`permissions.crm.segments_view`) }}</span>
              </div>
              <div class="field">
                <el-radio-group
                  v-model="pvPermissions.crm_segments_view"
                >
                  <div class="permission-column">
                    <el-radio
                      label="all"
                      class="checkbox"
                    >
                      {{ $t(`permissions.all`) }}
                    </el-radio>
                    <el-radio
                      label="teammates"
                      class="checkbox"
                    >
                      {{ $t(`permissions.teammates`) }}
                    </el-radio>
                  </div>
                  <div class="permission-column">
                    <el-radio
                      label="own"
                      class="checkbox"
                    >
                      {{ $t(`permissions.own`) }}
                    </el-radio>
                    <el-radio
                      label="deny"
                      class="checkbox"
                    >
                      {{ $t(`permissions.deny`) }}
                    </el-radio>
                  </div>
                </el-radio-group>
              </div>
            </div>
            <div class="card-content">
              <div class="field">
                <span class="label">{{ $t(`permissions.crm.segments_edit`) }}</span>
              </div>
              <div class="field">
                <el-radio-group
                  v-model="pvPermissions.crm_segments_edit"
                  :disabled="pvPermissions.crm_segments_view === 'deny'"
                >
                  <div class="permission-column">
                    <el-radio
                      label="all"
                      class="checkbox"
                      :disabled="isDisabledOption('crm_segments_view', 'all')"
                    >
                      {{ $t(`permissions.all`) }}
                    </el-radio>
                    <el-radio
                      label="teammates"
                      class="checkbox"
                      :disabled="isDisabledOption('crm_segments_view', 'teammates')"
                    >
                      {{ $t(`permissions.teammates`) }}
                    </el-radio>
                  </div>
                  <div class="permission-column">
                    <el-radio
                      label="own"
                      class="checkbox"
                      :disabled="isDisabledOption('crm_segments_view', 'own')"
                    >
                      {{ $t(`permissions.own`) }}
                    </el-radio>
                    <el-radio
                      label="deny"
                      class="checkbox"
                      :disabled="isDisabledOption('crm_segments_view', 'deny')"
                    >
                      {{ $t(`permissions.deny`) }}
                    </el-radio>
                  </div>
                </el-radio-group>
              </div>
            </div>
            <div class="card-content">
              <div class="field">
                <span class="label">{{ $t(`permissions.crm.segments_review`) }}</span>
              </div>
              <div class="field">
                <el-radio-group
                  v-model="pvPermissions.crm_segments_review"
                  :disabled="pvPermissions.crm_segments_edit === 'deny' || pvPermissions.crm_segments_view === 'deny'"
                >
                  <div class="permission-column">
                    <el-radio
                      label="all"
                      class="checkbox"
                      :disabled="isDisabledOption('crm_segments_edit', 'all')"
                    >
                      {{ $t(`permissions.all`) }}
                    </el-radio>
                    <el-radio
                      label="teammates"
                      class="checkbox"
                      :disabled="isDisabledOption('crm_segments_edit', 'teammates')"
                    >
                      {{ $t(`permissions.teammates`) }}
                    </el-radio>
                  </div>
                  <div class="permission-column">
                    <el-radio
                      label="own"
                      class="checkbox"
                      :disabled="isDisabledOption('crm_segments_edit', 'own')"
                    >
                      {{ $t(`permissions.own`) }}
                    </el-radio>
                    <el-radio
                      label="deny"
                      class="checkbox"
                      :disabled="isDisabledOption('crm_segments_edit', 'deny')"
                    >
                      {{ $t(`permissions.deny`) }}
                    </el-radio>
                  </div>
                </el-radio-group>
              </div>
            </div>
          </div>
        </div>
        <div class="permission-column">
          <div class="card">
            <div class="title">
              <span>{{ $t(`permissions.crm.activities`) }}</span>
            </div>
            <div class="card-content">
              <div class="field">
                <span class="label">{{ $t(`permissions.crm.activities_view`) }}</span>
              </div>
              <div class="field">
                <el-radio-group
                  v-model="pvPermissions.crm_activities_view"
                >
                  <div class="permission-column">
                    <el-radio
                      label="all"
                      class="checkbox"
                    >
                      {{ $t(`permissions.all`) }}
                    </el-radio>
                    <el-radio
                      label="teammates"
                      class="checkbox"
                    >
                      {{ $t(`permissions.teammates`) }}
                    </el-radio>
                  </div>
                  <div class="permission-column">
                    <el-radio
                      label="own"
                      class="checkbox"
                    >
                      {{ $t(`permissions.own`) }}
                    </el-radio>
                    <el-radio
                      label="deny"
                      class="checkbox"
                    >
                      {{ $t(`permissions.deny`) }}
                    </el-radio>
                  </div>
                </el-radio-group>
              </div>
            </div>
            <div class="card-content">
              <div class="field">
                <span class="label">{{ $t(`permissions.crm.activities_edit`) }}</span>
              </div>
              <div class="field">
                <el-radio-group
                  v-model="pvPermissions.crm_activities_edit"
                  :disabled="pvPermissions.crm_activities_view === 'deny'"
                >
                  <div class="permission-column">
                    <el-radio
                      label="all"
                      class="checkbox"
                      :disabled="isDisabledOption('crm_activities_view', 'all')"
                    >
                      {{ $t(`permissions.all`) }}
                    </el-radio>
                    <el-radio
                      label="teammates"
                      class="checkbox"
                      :disabled="isDisabledOption('crm_activities_view', 'teammates')"
                    >
                      {{ $t(`permissions.teammates`) }}
                    </el-radio>
                  </div>
                  <div class="permission-column">
                    <el-radio
                      label="own"
                      class="checkbox"
                      :disabled="isDisabledOption('crm_activities_view', 'own')"
                    >
                      {{ $t(`permissions.own`) }}
                    </el-radio>
                    <el-radio
                      label="deny"
                      class="checkbox"
                      :disabled="isDisabledOption('crm_activities_view', 'deny')"
                    >
                      {{ $t(`permissions.deny`) }}
                    </el-radio>
                  </div>
                </el-radio-group>
              </div>
            </div>
            <div class="card-content">
              <div class="field">
                <span class="label">{{ $t(`permissions.crm.activities_review`) }}</span>
              </div>
              <div class="field">
                <el-radio-group
                  v-model="pvPermissions.crm_activities_review"
                  :disabled="pvPermissions.crm_activities_view === 'deny'"
                >
                  <div class="permission-column">
                    <el-radio
                      label="all"
                      class="checkbox"
                      :disabled="isDisabledOption('crm_activities_view', 'all')"
                    >
                      {{ $t(`permissions.all`) }}
                    </el-radio>
                    <el-radio
                      label="teammates"
                      class="checkbox"
                      :disabled="isDisabledOption('crm_activities_view', 'teammates')"
                    >
                      {{ $t(`permissions.teammates`) }}
                    </el-radio>
                  </div>
                  <div class="permission-column">
                    <el-radio
                      label="own"
                      class="checkbox"
                      :disabled="isDisabledOption('crm_activities_view', 'own')"
                    >
                      {{ $t(`permissions.own`) }}
                    </el-radio>
                    <el-radio
                      label="deny"
                      class="checkbox"
                      :disabled="isDisabledOption('crm_activities_view', 'deny')"
                    >
                      {{ $t(`permissions.deny`) }}
                    </el-radio>
                  </div>
                </el-radio-group>
              </div>
            </div>
            <div class="card-content">
              <div class="field">
                <span class="label">{{ $t(`permissions.crm.activities_run`) }}</span>
              </div>
              <div class="field">
                <el-radio-group
                  v-model="pvPermissions.crm_activities_run"
                  :disabled="pvPermissions.crm_activities_view === 'deny'"
                >
                  <div class="permission-column">
                    <el-radio
                      label="all"
                      class="checkbox"
                      :disabled="isDisabledOption('crm_activities_view', 'all')"
                    >
                      {{ $t(`permissions.all`) }}
                    </el-radio>
                    <el-radio
                      label="teammates"
                      class="checkbox"
                      :disabled="isDisabledOption('crm_activities_view', 'teammates')"
                    >
                      {{ $t(`permissions.teammates`) }}
                    </el-radio>
                  </div>
                  <div class="permission-column">
                    <el-radio
                      label="own"
                      class="checkbox"
                      :disabled="isDisabledOption('crm_activities_view', 'own')"
                    >
                      {{ $t(`permissions.own`) }}
                    </el-radio>
                    <el-radio
                      label="deny"
                      class="checkbox"
                      :disabled="isDisabledOption('crm_activities_view', 'deny')"
                    >
                      {{ $t(`permissions.deny`) }}
                    </el-radio>
                  </div>
                </el-radio-group>
              </div>
            </div>
          </div>
        </div>
      </el-collapse-item>
      <el-collapse-item
        :title="$t(`permissions.others.title`)"
        name="others"
      >
        <div class="permission-column">
          <div class="card">
            <div class="title">
              <span>{{ $t(`permissions.tools.title`) }}</span>
              <span>
                <span class="radio-block radio-block_allow">
                  {{ $t(`permissions.status`) }}
                </span>
              </span>
            </div>
            <div class="card-content">
              <vnodes :vnodes="renderPermissionSwitchControl('tools_promo_codes_checker_view')" />

              <vnodes :vnodes="renderPermissionSwitchControl('tools_promo_landings_view')" />

              <vnodes :vnodes="renderPermissionSwitchControl('tools_promo_landings_edit')" />

              <vnodes :vnodes="renderPermissionSwitchControl('marketing_materials_view')" />

              <vnodes :vnodes="renderPermissionSwitchControl('marketing_materials_edit')" />
            </div>
          </div>
          <div class="card">
            <div class="title">
              <span>{{ $t(`permissions.live.live_card_title`) }}</span>
              <span>
                <span class="radio-block radio-block_allow">
                  {{ $t(`permissions.status`) }}
                </span>
              </span>
            </div>
            <div class="card-content">
              <vnodes :vnodes="renderPermissionSwitchControl('last_bets_and_deposits_view')" />

              <vnodes :vnodes="renderPermissionSwitchControl('top_events_view')" />
            </div>
          </div>
        </div>
        <div class="permission-column">
          <div class="card">
            <div class="title">
              <span>{{ $t(`permissions.geo.geo_card_title`) }}</span>
            </div>
            <div class="card-content">
              <div class="field">
                <el-radio-group
                  v-model="pvPermissions.countries_allowed_mode"
                  :disabled="pvPermissions.reports_view === 'deny'"
                  @change="checkGeoPermission(pvPermissions.countries_allowed_mode)"
                >
                  <div class="permission-column">
                    <el-radio
                      label="allow_all"
                      class="checkbox"
                    >
                      {{ $t(`permissions.allow_all`) }}
                    </el-radio>
                    <el-radio
                      label="deny_all"
                      class="checkbox"
                    >
                      {{ $t(`permissions.deny_all`) }}
                    </el-radio>
                  </div>
                  <div class="permission-column">
                    <el-radio
                      label="allow_selected"
                      class="checkbox"
                    >
                      {{ $t(`permissions.allow_selected`) }}
                    </el-radio>
                    <el-radio
                      label="deny_selected"
                      class="checkbox"
                    >
                      {{ $t(`permissions.deny_selected`) }}
                    </el-radio>
                  </div>
                </el-radio-group>
                <ui-tags-input
                  v-model="pvPermissions.countries_allowed_list"
                  :disabled="pvPermissions.countries_allowed_mode === 'allow_all' || pvPermissions.countries_allowed_mode === 'deny_all'"
                  :typeahead-activation-threshold="0"
                  :only-existing-tags="true"
                  :existing-tags="countryList"
                  :typeahead="true"
                  :placeholder="$t(`permissions.add_country`)"
                  :class="errors.countries_allowed_list ? 'error-tags' : ''"
                  class="card-filter__input-tag card-filter__input-tag--z"
                  typeahead-style="dropdown"
                />
                <p
                  v-if="errors.countries_allowed_list"
                  class="input-error"
                >
                  {{ errors.countries_allowed_list[0].message }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import app from '@/main';
import { dependsOnConfig, requiresForConfig } from '@/configs/permissions';
import { getPermissionsObject, getPermissionsSnapshot, sortPermissionsSnapshot } from '@/helpers/permissions';
import PermissionTree from '@/components/PermissionTree';
import errorHandleMixin from '@/views/mixins/error-hadle';


export default {
  name: 'TeamPermissions',
  components: {
    PermissionTree,
    Vnodes: {
      functional: true,
      render: (h, ctx) => (ctx.props.vnodes ? ctx.props.vnodes : ''),
    },
  },
  mixins: [
    errorHandleMixin,
  ],
  props: {
    teamUsers: {
      type: Array,
      default() {
        return [];
      },
    },
    permissions: {
      type: Object,
      default() {
        return {
          affiliates_types_edit: [],
          affiliates_types_view: [],
          players_available_property_groups: [],
          media_buying_reports_affiliates_types_view: [],
          reports_affiliates_types: [],
          media_buying_payments_affiliates_types_view: [],
          media_buying_payments_affiliates_types_review: [],
          media_buying_posts_affiliates_types_view: [],
          media_buying_posts_affiliates_types_review: [],
          media_buying_posts_statuses_visible: [],
          reports_view_affiliates_visibility_teammates_id: [],
        };
      },
    },
    team: {
      type: Object,
      default() {
        return {};
      },
    },

    edit: {
      type: Boolean,
      default() {
        return true;
      },
    },
  },

  data() {
    return {
      isLoading: false,
      activeColor: this.$theme.accentColor,
      inactiveColor: this.$theme.dangerColor,
      pvPermissions: {},
      users: null,
      currencyOptions: [],
      errors: {},
      isEdited: true,
      permissionSnapshots: {},
      activePermissions: ['reports'],
      crmWeight: {
        all: 3,
        teammates: 2,
        own: 1,
        deny: 0,
      },
      crmAllowOptions: {
        all: ['all', 'teammates', 'own', 'deny'],
        teammates: ['teammates', 'own', 'deny'],
        own: ['own', 'deny'],
        deny: ['deny'],
      },
    };
  },

  computed: {
    ...mapGetters({
      countries: 'misc/countries',
    }),

    activeTeamId: {
      get() {
        return this.$route.params.id ? this.$route.params.id : '';
      },
    },

    usersList: {
      get() {
        if (this.users === null) {
          return null;
        }
        const users = this.$_.cloneDeep(this.users);
        const usersList = {};
        users.forEach((e) => {
          usersList[e.id] = e.email;
        });
        return usersList || '';
      },
    },
    countryList: {
      get() {
        const countries = this.$_.clone(this.countries);
        const countryList = {};
        countries.forEach((e) => {
          countryList[e.code] = e.name;
        });
        return countryList || '';
      },
    },
  },

  watch: {
    permissions: {
      handler(value) {
        this.getUsers();
        this.getCurrencies();
        this.pvPermissions = this.$_.cloneDeep(value);
      },
      immediate: true,
    },

    pvPermissions: {
      handler(value) {
        this.isEdited = this.$_.isEqual(this.permissions, value);
        this.$emit('update:edit', this.isEdited);
      },
      deep: true,
    },

    'pvPermissions.crm_segments_view': {
      handler() {
        this.checkWeight('crm_segments_view', 'crm_segments_edit');
      },
      deep: true,
    },
    'pvPermissions.crm_segments_edit': {
      handler() {
        this.checkWeight('crm_segments_edit', 'crm_segments_review');
      },
      deep: true,
    },
    'pvPermissions.crm_activities_view': {
      handler() {
        this.checkWeight('crm_activities_view', 'crm_activities_edit');
        this.checkWeight('crm_activities_view', 'crm_activities_run');
        this.checkWeight('crm_activities_view', 'crm_activities_review');
      },
      deep: true,
    },
  },

  created() {
    app.$eventBus.$on('save-changes', this.postTeamPermissions);
    app.$eventBus.$on('cancel-changes', this.cancelEdit);
  },

  mounted() {

  },

  beforeDestroy() {
    app.$eventBus.$off('save-changes', this.postTeamPermissions);
    app.$eventBus.$off('cancel-changes', this.cancelEdit);
  },

  methods: {
    getUsers() {
      this.isLoading = true;
      this.errors = {};
      const _query = {
        team_id: this.activeTeamId,
      };
      this.$api.getAdmins(_query).then((response) => {
        this.users = response.data.payload;
      })
        .catch((error) => {
          this.errors = this.$_.cloneDeepWith(error.data.errors);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getCurrencies() {
      this.$api.getMiscCustomerCurrencies().then((res) => {
        this.currencyOptions = res.data.payload;
      });
    },
    checkWeight(currentOption, childOption) {
      const currentOptionVal = this.pvPermissions[currentOption];
      const childOptionVal = this.pvPermissions[childOption];
      if (childOptionVal && (this.crmWeight[childOptionVal] > this.crmWeight[currentOptionVal])) {
        this.pvPermissions[childOption] = this.pvPermissions[currentOption];
      }
    },
    isDisabledOption(property, option) {
      const value = this.pvPermissions[property];
      return !this.crmAllowOptions[value].includes(option);
    },

    async postTeamPermissions() {
      this.errors = {};
      await this.$api.postTeamPermissions(this.activeTeamId, this.pvPermissions).then((response) => {
        app.$eventBus.$emit('update-permissions', response.data.payload);
      })
        .catch((error) => {
          this.errors = this.$_.cloneDeepWith(error.data.errors);
        });
    },

    checkGeoPermission(permission) {
      this.errors = {};
      if (permission === 'allow_all' || permission === 'deny_all') {
        this.pvPermissions.countries_allowed_list = [];
      }
    },

    cancelEdit() {
      this.errors = {};
      this.pvPermissions = this.$_.cloneDeep(this.permissions);
    },

    permissionIsDeny(permission) {
      return this.pvPermissions[permission] === 'deny';
    },

    updatePermissions(currentPermission) {
      const currentPermissionsSettings = requiresForConfig[currentPermission];
      if (currentPermissionsSettings !== undefined && this.permissionIsDeny(currentPermission)) {
        // Get current state of permissions for switch off and save
        const permissionShapshot = getPermissionsSnapshot(this.pvPermissions, currentPermissionsSettings);
        this.permissionSnapshots = { ...this.permissionSnapshots, [currentPermission]: permissionShapshot };

        this.pvPermissions = { ...this.pvPermissions, ...getPermissionsObject(currentPermissionsSettings) };
      } else {
        // Return saved permissions state
        const permissionShapshot = sortPermissionsSnapshot(this.permissionSnapshots[currentPermission], this.pvPermissions);
        this.pvPermissions = { ...this.pvPermissions, ...permissionShapshot };
      }
    },

    getPermissionDepends(currentPermission) {
      return dependsOnConfig[currentPermission] ? dependsOnConfig[currentPermission] : [];
    },

    permissionIsDisabled(currentPermission) {
      const dependsOn = this.getPermissionDepends(currentPermission);

      return dependsOn.some(dependency => this.pvPermissions[dependency] === 'deny');
    },

    renderPermissionSwitchControl(permissionName) {
      const elSwitch = this.$createElement('el-switch', {
        class: 'label',
        props: {
          disabled: this.permissionIsDisabled(permissionName),
          activeColor: this.activeColor,
          inactiveColor: this.inactiveColor,
          activeValue: 'allow',
          inactiveValue: 'deny',
          value: this.pvPermissions[permissionName],
        },
        on: {
          change: (value) => {
            this.pvPermissions = { ...this.pvPermissions, [permissionName]: value };
            this.updatePermissions(permissionName);
          },
        },
      });

      const depends = this.getPermissionDepends(permissionName).map(dependency => this.$createElement('li', { class: { 'dependency-item': true, selected: this.pvPermissions[dependency] === 'allow' } }, this.$t(`permissions.list.${dependency}`)));
      const dependsOnText = this.$createElement('h3', { class: 'depends-on-header' }, this.$t('permissions.depends'));
      const contentSlot = this.$createElement('div', { slot: 'content' }, [dependsOnText, depends]);
      const elTooltip = this.$createElement('el-tooltip', { props: { placement: 'right' } }, [elSwitch, contentSlot]);
      const switchWrapper = this.$createElement('div', { class: 'values' }, depends.length ? [elTooltip] : [elSwitch]);

      const label = this.$createElement('span', { class: 'label' }, this.$t(`permissions.list.${permissionName}`));

      return this.$createElement('div', { class: 'field' }, [label, switchWrapper]);
    },
  },
};
</script>

<style lang="scss">
.depends-on-header {
  margin-bottom: 5px;
}

.dependency-item {
  &.selected {
    text-decoration: line-through;
  }
}
</style>
