<template>
  <div
    v-if="isOpen"
    class="upload-files-popup"
  >
    <div
      v-loading="isFilesLoading"
      class="popup"
    >
      <div class="head">
        <span class="name">{{ $t(`payments.uploadFilesPopup.title`) }}</span>
        <ui-icon
          :size="20"
          class="close"
          lib="fa"
          substyle="fas"
          name="times"
          @click.native="close"
        />
      </div>
      <div class="form full-width">
        <div class="row">
          <div class="field">
            <span class="label-text">{{ $t(`payments.uploadFilesPopup.label`) }}</span>
            <el-upload
              ref="upload"
              class="upload"
              accept=".pdf, .doc, .docx, .png, .jpg, .jpeg"
              drag
              multiple
              action=""
              :file-list="fileList"
              :http-request="uploadFilesAction"
              :on-remove="removeFile"
              :auto-upload="false"
            >
              <div>
                <i class="el-icon-upload" />
                <div class="el-upload__text">
                  {{ $t('payments.uploadFilesPopup.drop-file-here-or') }} <em>{{ $t('payments.uploadFilesPopup.click-to-upload') }}</em>
                </div>
              </div>
            </el-upload>
          </div>
        </div>
      </div>
      <div class="foot">
        <ui-button
          color="red"
          class="btn cancel-button"
          lib="fa"
          substyle="fas"
          icon="times"
          size="big"
          @click.native="close"
        >
          {{ $t(`payments.uploadFilesPopup.cancel`) }}
        </ui-button>
        <ui-button
          filled
          lib="fa"
          substyle="fas"
          icon="check"
          size="big"
          @click="submitUpload"
        >
          {{ $t(`payments.uploadFilesPopup.save`) }}
        </ui-button>
      </div>
    </div>
    <div class="wrapper" />
  </div>
</template>

<script>
export default {
  name: 'UploadFilesPopup',

  props: {
    uploadedFiles: {
      type: Array,
      default() {
        return [];
      },
    },
    paymentId: {
      type: String,
      default() {
        return '';
      },
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      files: [],
      fileList: [],
      filesCount: 0,
      isFilesLoading: false,
      isDataLoading: false,
    };
  },

  watch: {
    isOpen(value) {
      if (value) {
        this.files = [];
        this.fileList = [];
        if (this.uploadedFiles.length > 0) {
          this.uploadedFiles.forEach((file) => {
            this.fileList.push({ name: file.filename, id: file.id });
            this.files.push(file.id);
          });
        }
      }
    },
  },


  methods: {
    close() {
      this.$emit('close');
    },

    submitUpload() {
      this.isFilesLoading = true;
      this.filesCount = this.$refs.upload.uploadFiles.filter(e => e.status === 'ready').length;
      if (this.filesCount > 0) {
        this.uploadFileToServer();
      } else {
        this.saveFilesForPayment();
      }
    },

    async saveFilesForPayment() {
      await this.$api.postPaymentInfoFiles(this.paymentId, this.files).finally(() => {
        this.isFilesLoading = false;
      });
      this.$emit('close');
      this.$emit('update-payment-info');
    },

    removeFile(file) {
      const fileId = this.files.indexOf(file.id);
      if (fileId !== -1) {
        this.files.splice(fileId, 1);
      }
    },

    uploadFileToServer() {
      this.$refs.upload.submit();
    },

    uploadFilesAction(file) {
      const formData = new FormData();
      formData.append('files', file.file);
      this.$api
        .postFile(formData)
        .then((response) => {
          this.files.push(response.data.payload.files.id);
          this.filesCount -= 1;
          if (this.filesCount === 0) {
            this.saveFilesForPayment();
          }
        })
        .catch(() => {
          this.isFilesLoading = false;
        });
    },
  },
};
</script>
