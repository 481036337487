export default {
  affiliates: {
    name: 'Affiliates',
    filters: {
      status: 'Status',
      country: 'Country',
      onlyMultiaccounts: 'Only Multiaccounts',
      introducedBy: 'Introduced By',
      subAffiliatesProgram: 'Sub-Affiliates Program',
      mediaBuying: 'Media Buying',
      createPayments: 'Create Payments',
      paymentMethod: 'Payment Method',
      wallet: 'Wallet',
      paymentPeriod: 'Payment Period',
      negativeCarryover: 'Negative Carryover',
      promoCode: 'Promo Code',
    },
    menu: {
      list: 'Affiliate List',
      review: 'Affiliate Review',
      requests: 'Affiliate Requests',
      groups: 'Groups',
    },
    time: {
      days: '{count} days',
      hours: '{count} hours',
      milliseconds: '{count} milliseconds',
      minutes: '{count} minutes',
      months: '{count} months',
      seconds: '{count} seconds',
      years: '{count} years',
    },
    delete_offer: {
      title: 'Delete Offer',
      msg: 'You’re trying to delete offer having players pinned to it. Select another offer from current affiliate or just unpin all players.',
      offer: 'Offer',
      select_offer: 'Select Offer',
      required: 'This field is required',
      or: 'or',
      unpin_player: 'Unpin Players',
      select_aff: 'Select Affiliate',
      cancel: 'Cancel',
      confirm: 'Confirm',
    },
    public_profile: {
      title: 'Public Profile',
      no_data: 'No Public Profile',
      confirm: {
        title: 'Delete Public Profile?',
        msg: 'Are you sure you want to delete public profile?',
      },
      edit: {
        title: 'Edit Public Profile',
        name: 'Name',
        misc: 'Misc',
        misc_placeholder: 'Public Note',
        add_messenger: 'Add Another Messenger',
        messenger: 'Messenger',
        messenger_id: 'Messenger ID',
      },
    },
    offer_popup: {
      range_min: 'Min',
      range_max: 'Max',
      copy_of: 'Copy of',
      rule: 'Rule',
      Add: 'Add',
      Edit: 'Edit',
      offer: 'Offer',
      general: 'General',
      offer_label: 'Offer Name',
      brands: 'Brands',
      brands_all: 'All',
      brands_only_selected: 'Only Selected',
      add_brands: 'Select Brands',
      offer_placeholder: 'Offer Name',
      g_daily_cap: 'Global Daily Cap',
      g_hold: 'Global Hold',
      rules: 'Rules',
      add_rules: 'Add New Rule',
      rule_label: 'Name',
      rule_placeholder: 'Rule 2',
      rev_share: 'RevShare',
      sport: 'Sport',
      sport_casino: 'Sport & Casino',
      seporate: 'Separate',
      casino: 'Casino',
      cpa: 'CPA',
      inherit: 'Inherit',
      daily_cap: 'Daily Cap',
      cap_players: 'Cap Players',
      per: 'per',
      hold: 'Hold',
      day: 'Days',
      filters: 'Filters',
      add_filter: 'Add Filter',
      country: 'Countries',
      avalible: 'Availiable',
      deny: 'Deny',
      add_country: 'Add a country',
      sub_id: 'Sub ID',
      equals: 'Equals',
      not_equals: 'Not Equals',
      info_subid: 'Press Enter after entering SubID',
      sub_id_placeholder: 'Add a Sub ID',
      payment_rules: 'Payment Rules',
      payout_rules: 'Payout Rules',
      cpa_conditions: 'CPA Conditions',
      add_rule: 'Add Rule',
      add_condition: 'Add Condition',
      add_cpa_payout: 'Add CPA Payout',
      cpa_payout: 'CPA Payout',
      deposits: 'Deposits',
      deposit_count: 'Deposits Сount',
      deposit_sum: 'Deposits Sum',
      deposit_amount: 'Deposit Amount',
      sport_bet: 'Sport Bets',
      bet_count: 'Bets Сount',
      bet_sum: 'Bets Sum',
      bet_amount: 'Bet Amount',
      bet_odd: 'Bets Odd',
      casino_bets: 'Casino Bets',
      cancel: 'Cancel',
      save: 'Save',
      apply_now: 'Apply Now',
      apply: 'Apply',
      eff_date_time: 'Effective Date & Time',
      schedule_changes: 'Schedule Changes',
      payout: 'Payout',
      only_selected: 'Only Selected',
      except_selected: 'Except Selected',
      use_global: 'Use Global',
      add_level: 'Add Level',
      separate: 'Separate',
      empty_cpa_conditions: 'At least one CPA condition should be set',
      confirm_rule: {
        title: 'Delete Offer Rule?',
        msg: 'Are you sure you want to delete the rule?',
        action: 'delete rule',
      },
      confirm_filter: {
        title: 'Delete Offer Filter?',
        msg: 'Are you sure you want to delete the filter?',
        action: 'delete filter',
      },
      confirm_payment: {
        title: 'Delete Offer Condition?',
        msg: 'Are you sure you want to delete the condition?',
        action: 'delete condition',
      },
      confirm_cpa_payout: {
        title: 'Delete CPA Payout?',
        msg: 'Are you sure you want to delete the CPA payout?',
        action: 'delete CPA payout',
      },
      confirm_close: {
        title: 'Close Without Saving?',
        msg: 'Are you sure you want to close window without saving?',
        action: 'Close',
        closeAction: 'Cancel',
      },
    },
    transfer_popup: {
      from: 'From Affiliate',
      to: 'To Affiliate',
      placeholder_affiliate: 'Select Affiliate',
      sub_affiliates: 'Sub-Affiliates',
      all: 'All',
      selected: 'Selected',
      country: 'Country',
      placeholder_country: 'Select Countries',
      earning: '30 Days Earnings',
      amount: 'Amount',
      balance: 'Balance',
      registration_date: 'Registration Date',
      emptyAffiliates: '<span class="bold">Select Affiliate</span>&nbsp;to transfer Sub-Affiliates',
      transferSubAffiliates: 'Transfer Sub-Affiliates',
      transferSubAffiliatesCounter: '<span class="bold">Sub-Affiliates</span> will be transferred',
      transfer: 'Transfer',
      direct_selection: 'Direct Selection',
      conditional_filter: 'Conditional Filter',
      fields: {
        incrementing_id: {
          title: 'ID',
        },
        name: {
          title: 'Name',
        },
        email: {
          title: 'E-Mail',
        },
      },
    },
    offers: {
      revshare: {
        label: 'Revenue Share',
        fields: {
          rev_share_percent: 'revshare',
          hold: 'hold',
        },
      },
      cpa: {
        label: 'CPA',
        fields: {
          payout: 'payout',
          daily_cap: 'Daily Cap',
          hold: 'hold',
        },
      },
      deposits: {
        label: 'Deposits',
        fields: {
          deposits_count: 'Deposits count',
          deposits_sum: 'Total deposits sum',
          deposit_value: 'One deposit sum',
        },
      },
      sports: {
        label: 'Sports Bets',
        fields: {
          bets_count: 'Bets count',
          bets_sum: 'Total bets sum',
          bet_value: 'One bet sum',
          bet_coefficient: 'odd value',
        },
      },
      games: {
        label: 'Casino Bets',
        fields: {
          bet_games_count: 'Bet Games count',
          bet_games_sum: 'Total Bet games sum',
          bet_game_value: 'One Bet game sum',
        },
      },
    },
    sublist: {
      fields: {
        messenger_id: {
          title: 'Messenger',
        },
        email: {
          title: 'E-Mail',
        },
        first_name: {
          title: 'Name',
        },
        name: {
          title: 'Name',
        },
        created_at: {
          title: 'Registration Date',
        },
      },
    },
    dedicated_domains: {
      fields: {
        incrementing_id: {
          title: 'ID',
        },
        domain_name: {
          title: 'Domain Name',
        },
        affiliate_offer_name: {
          title: 'Offer name',
        },
        created_at: {
          title: 'Registration Date',
        },
      },
    },
    promocodes_list: {
      fields: {
        promo_code: {
          title: 'Promo Code',
        },
        admin_name: {
          title: 'Admin',
        },
        created_at: {
          title: 'Created at',
        },
      },
    },
    notifications: {
      title: 'Notifications',
      affiliates: 'Affiliates',
      sending: 'Sending...',
      popup: {
        notificationDetails: 'Notification Details',
        notification: 'Notification',
        sendNotification: 'Send Notification',
        title: 'Title',
        description: 'Description',
        requiredRead: 'Required to Read',
        all: 'All',
        onlySelected: 'Only Selected',
        allExcept: 'All Except',
        confirmSend: 'Send Notification | Send {count} Notifications | Send {count} Notifications | Send {count} Notifications',

        statuses: {
          read: 'Read',
          delivered: 'Delivered',
        },
        fields: {
          affiliate_email: {
            title: 'E-Mail',
          },
          status: {
            title: 'Status',
          },
        },
      },
      fields: {
        created_at: {
          title: 'Date',
        },
        title: {
          title: 'Title',
        },
        message: {
          title: 'Description',
        },
      },
    },
    list: {
      fields: {
        incrementing_id: {
          title: 'ID',
        },
        email: {
          title: 'Email',
        },
        group_id: {
          title: 'Group',
        },
        country_code: {
          title: 'Country',
        },
        team_name: {
          title: 'Team',
        },
        referred_by_email: {
          title: 'Introduced By',
        },
        last_month_revenue: {
          title: '30 Days Earning',
        },
        created_at: {
          title: 'Registration Date',
        },
        site: {
          title: 'Site',
        },
        site_url: {
          title: 'Site',
        },
        available_balance: {
          title: 'Balance',
        },
        hold_balance: {
          title: 'Hold',
        },
        offer_name: {
          title: 'Offer name',
        },
        timestamp: {
          title: 'Date',
        },
        description: {
          title: 'Description',
        },
        action_timestamp: {
          title: 'Date',
        },
        action_name: {
          title: 'Description',
        },
        action: {
          title: 'Action',
        },
        ip_address: {
          title: 'IP Address',
        },
        initiator: {
          title: 'Initiator',
        },
        initiator_id: {
          title: 'Initiator ID',
        },
        os: {
          title: 'OS',
        },
        device: {
          title: 'Device',
        },
        grouping: {
          title: 'Date',
        },
        transaction_type: {
          title: 'Transaction Type',
        },
        transaction_description: {
          title: 'Description',
        },
        amount_to_hold: {
          title: 'Credited (in hold)',
        },
        amount_to_available: {
          title: 'Credited (available)',
        },
        result_balance_hold: {
          title: 'Hold Balance',
        },
        result_balance_available: {
          title: 'Available Balance',
        },
      },
    },
    payments: {
      fields: {
        incrementing_id: {
          title: 'ID',
        },
        created_at: {
          title: 'Created at',
        },
        amount: {
          title: 'Amount',
        },
        moderation_status: {
          title: 'Moderation',
        },
        payout_status: {
          title: 'Payout',
        },
        payment_period_end: {
          title: 'Payment Period',
        },
        approved_by_admin_name: {
          title: 'Approved By',
        },
        site_payment_method_name: {
          title: 'Method',
        },
        payment_wallet: {
          title: 'Wallet',
        },
        paid_at: {
          title: 'Paid At',
        },
      },
    },
    listView: {
      toolbar: [
        {
          search: {
            placeholder: 'Search ...',
          },
          groupFilter: {
            label: 'Group',
            placeholder: 'All',
          },
          countryFilter: {
            label: 'Country',
            placeholder: 'All',
          },
          refreshBtn: {
            text: 'Refresh',
          },
        },
        {
          statusFilter: {
            active: 'Active',
            on_review: 'On Review',
            approved: 'Approved',
            declined: 'Declined',
            not_paid: 'Not Paid',
            for_rework: 'For Rework',
            processing: 'Processing',
            paid: 'Paid',
            failed: 'Failed',
            blocked: 'Blocked',
            rejected: 'Rejected',
            multiaccounts: 'Only Multiaccounts',
          },
          export: {
            label: 'Export',
          },
          exportBtn: {
            text: 'Export',
          },
        },
      ],
      table: {
        headers: {
          email: 'Email',
          group_id: 'Group',
          country_code: 'Country',
          last_month_revenue: '30 Days Earning',
          created_at: 'Registration Date',
          site: 'Site',
          available_balance: 'Balance',
          hold_balance: 'Hold',
        },
      },
    },
    cardView: {
      emptyField: 'Not Specified',
      loadingData: 'Loading data',
      nav: {
        info: 'Information',
        sub: 'Sub-Affiliates',
        offers: 'Offers',
        promocodes: 'Promo Codes',
        bonuses: 'Bonuses',
        payments: 'Payments History',
        estpayment: 'Payments Report',
        dedication: 'Dedicated Domains',
      },
      toolbar: {
        backLinks: ['Affiliate List', 'Affiliate Review', 'Balance History', 'History'],
        btns: {
          block: 'Block Affiliate',
          activate_manual: 'Activate Manually',
          create_payment: 'Create Payment',
          unblock: 'Unblock Affiliate',
          login: 'Log In',
          statistics: 'Statistics',
          reset: 'Reset Password',
          resetTotp: 'Reset 2FA',
          edit: 'Edit',
          cancel: 'Cancel',
          save: 'Save changes',
          decline: 'Decline',
          note_decline: 'Decline With Notification',
          approve: 'Approve',
          addOffer: 'Add Offer',
          close: 'Close',
          dontSave: 'Don’t Save',
          revoke: 'To Review',
          addSubAffiliate: 'ADD SUB-AFFILIATE',
        },
      },
      info: {
        system: 'System',
        balance: 'Balance',
        holdBalance: 'Hold Balance',
        paymentMethod: 'Payment Method',
        walletNumber: 'Wallet number',
        wallet: 'Wallet',
        paymentPeriod: 'Payment Period',
        lastLogin: 'Last login',
        lastLoginEmpty: 'No login attempts',
        regDae: 'Registration date',
        historyTitle: 'History',
        noHistoryTitle: 'No History',
        historyLink: 'Full History',
        group: 'Group',
        birthDay: 'Date of birth',
        introduced: 'Introduced by',
        introducedEmpty: 'There is no sub-affiliate pinned to affiliate',
        team: 'Team',
        brands: 'Available Brands  and Fees',
        country: 'Country',
        city: 'City',
        adress: ['Adress Line 1', 'Adress Line 2'],
        site: 'Site url',
        site_urls: 'Site URLs',
        add_url: 'Add URL',

        email: 'Email',
        fname: 'First name',
        surname: 'Surname',
        company: 'Company / Business name',
        phone: 'Contact number',
        messenger: 'Messenger',
        msgid: 'Messenger ID',
        negative_carryover: 'Negative Carryover',
        payments_enabled: 'Create Payments',
        personal_info: 'Personal Information',
        change_mail: 'Change email',
        status_yes: 'Yes',
        status_no: 'No',
        postal_code: 'ZIP Code',
        address: 'Address',
      },
      notes: {
        title: 'Notes',
        addNote: 'Add Note',
        newNote: 'Note Text',
        noNotes: 'No Notes',
        types: {
          all: 'All',
          user: 'User\'s',
          system: 'System',
        },
      },
    },
    offersView: {
      title: 'Offers',
      requests: {
        btns: {
          decline: 'Decline',
          declineNotification: 'Decline w/ notification',
          accept: 'Accept',
        },
      },
      offers: {
        btns: {
          unpin: 'Unpin',
          edit: 'Edit',
        },
      },
      labels: {
        revenueShare: 'Revenue Share',
        cpa: 'CPA',
        revshare: 'Revshare',
        hold: 'Hold',
        payout: 'Payout',
        cap: 'Daily Cap',
        depositAmount: 'DEPOSIT AMOUNT',
        depositSum: 'DEPOSIT SUM',
        depositNumbers: 'NUMBER OF DEPOSITS',
        betAmount: 'Bet AMOUNT',
        betSum: 'bet SUM',
        betNumbers: 'NUMBER OF bets (max)',
        countryFilter: 'Country Filter',
        trafficName: 'Traffic Source Name',
        trafficURL: 'Traffic Source URL',
      },
      filter: {
        title: 'Availiable Countries',
        list: {
          country_code: 'Countries',
          subid1: 'Sub ID 1',
          subid2: 'Sub ID 2',
          subid3: 'Sub ID 3',
          subid4: 'Sub ID 4',
          subid5: 'Sub ID 5',
        },
        rule: {
          deposits: 'Deposits',
          sport_bets: 'Sport Bets',
          casino_bets: 'Casino Bets',
        },
        quick: {
          asia: 'Asia',
          latin: 'Latin America',
          all: 'All',
          cis: 'Commonwealth of Independent States',
        },
      },
      holdRanges: {
        seconds: 'Seconds',
        minutes: 'Minutes',
        hours: 'Hours',
        days: 'Days',
        months: 'Months',
        weeks: 'Weeks',
      },
    },
    affiliatesActivity: {
      activityLog: 'Activity Log',
      affiliateInfo: 'Affiliate Info',
      dateRange: 'Date Range',
      week: 'Week',
      week_2: '2 Weeks',
      weeks: 'Weeks',
      month: 'Month',
      date: 'Date',
      description: 'Description',
      action: 'Action',
      ipAddress: 'IP Address',
      initiatorRole: 'Initiator Role',
      initiatorName: 'Initiator Name',
      initiatorId: 'Initiator ID',
      panel: 'From Panel',
      admin: 'From Admin',
      overall: 'Overall',
    },
    affiliatesInfo: {
      multiaccounts: 'Multiaccounts',
      login: 'Login',
      beta: 'beta',
      paymentinfo: 'Payment info',
      currency: {
        label: 'Currency',
        popup: {
          title: 'Change Currency',
        },
      },
      save: 'Save',
      edit: 'Edit',
      editFees: 'Edit Fees',
      history: 'History',
      openInfo: 'Open info',
      unpin: 'Unpin',
      pin: 'Pin',
      block_title: 'Block {affiliateEmail}',
      block_action: 'Block Affiliate',
      blockText: 'Are you sure want to block <span>{affiliateEmail}</span>?',
      unblock_title: 'Unblock {affiliateEmail}',
      unblock_action: 'Unblock Affiliate',
      unblockText: 'Are you sure want to unblock <span>{affiliateEmail}</span>?',
      approve: 'Approve',
      approve_title: 'Approve {affiliateEmail}',
      approve_action: 'Approve Affiliate',
      approveText: 'Are you sure want to approve affiliate <span>{affiliateEmail}</span>?',
      review_title: 'Send {affiliateEmail} To Review',
      toReviewText: 'Are you sure want to send affiliate <span>{affiliateEmail}</span> to review?',
      review_action: 'Send to Review',
      decline: 'Decline Affiliate',
      decline_and_notify: 'Decline And Notify Affiliate',
      declineText: 'Are you sure you want to decline <span>{affiliateEmail}</span>?',
      declineAndNotifyText: 'Are you sure you want to decline and notify <span>{affiliateEmail}</span>?',
      affiliate: 'affiliate',
      operation: 'Operation',
      inp_sum: 'Input Sum',
      sum: 'Sum',
      note: 'Note',
      note_about_user: 'Notes About User',
      reason: 'Reason',
      deposit: 'deposit',
      withdraw: 'withdraw',
      total: 'Total',
      in_hold: 'In Hold',
      available: 'Available',
      method: 'Method',
      wallet: 'Wallet',
      not_specefied: 'Not Specified',
      period: 'Period',
      login_reg: 'Login & Registration',
      report_setting: 'Reporting Settings',
      media_buying_setting: 'Media Buying',
      cancel: 'Cancel',
      cpa_profit: 'CPA Profit',
      cpa_profit_for_aff: 'CPA Profit for Sub-Affiliates',
      refshare_profit: 'RevShare Profit',
      refshare_profit_for_aff: 'RevShare Profit for Sub-Affiliates',
      media_buying_access: 'Access to Media Buying Section',
      rotation_groups_setting: 'Rotation Groups',
      set_as_main: 'Set as Main',
      notifications_setting: 'Notifications',
      max_limits: 'Max Limits',
      max_default: 'Default',
      max_api_keys: 'API Keys',
      max_promo_codes: 'Promo Codes',
      max_traffic_sources: 'Traffic Sources',
      max_postbacks_per_traffic_source: 'Postbacks Per Traffic Sources',
      max_tags_per_traffic_source: 'Tags Per Traffic Sources',
      no_brands: 'There are no available offers for you',
      fees: {
        title: 'Fees',
        default: 'Default',
        default_value: 'Default value: {value}',
        deposit: 'Deposit',
        withdrawal: 'Withdrawal',
        brand: 'Brand',
        deposits_cpa_commission: 'CPA Deposit',
        withdrawals_cpa_commission: 'CPA Withdrawal',
        deposits_rs_commission: 'RevShare Deposit',
        withdrawals_rs_commission: 'RevShare Withdrawal',
      },
    },
    affiliate_notifications: {
      type: {
        telegram: 'Telegram',
        email: 'E-mail',
      },
      disabled: 'Disabled',
      popup: {
        title: 'Notifications',
        notifications_enabled: 'Send Notifications',
        select_label: 'Send to',
      },
    },
    confirm: {
      limits_popup_title: 'Edit Maximum Limits',
      fees_popup_title: 'Edit Fees',

      change_email_title: 'Change Email for {name}',
      change_email_label: 'Input new email below and press «Change email»',
      change_email_placeholder: 'Email',

      edit_balance: 'Edit Balance',
      save: 'Save',
      edit: 'Edit',

      title_1: 'Edit Affiliate\'s Balance',
      msg_1: 'To edit <span>{affiliateEmail}</span> balance please chose the operation type and input needed sum and notes. All fields required.',

      title_2: 'Edit Affiliate’s Balance Confirmation',
      msg_2: 'Are you sure you want to edit <span>{affiliateEmail}</span> balance?',
      action_2: 'Yes',

      title_3: 'Edit Payment Info',
      msg_3: 'Choose new Payment Method and Payment Period for <span>{affiliateEmail}</span>?',

      title_5: 'Create Payment?',
      msg_5: 'Are you sure you want to create payment for <span>{affiliateEmail}</span>?',
      action_5: 'Create Payment',

      title_6: 'Unpin this affiliate from {refferedEmail}',
      msg_6: 'Are you sure you to unpin this affiliate from <span>{refferedEmail}</span>?',
      msg_6_2: 'Are you sure you to unpin this affiliate?',
      msg_6_3: 'Are you sure you to pin this affiliate?',
      action_6: 'Unpin',

      title_8_1: 'Decline And Notify {affiliateEmail}?',
      title_8_2: 'Decline {affiliateEmail}?',

      title_9: 'Success',
      msg_9: 'Payment was <span> successfully created </span>',
      action_9: 'Done',

      title_10: 'Unable to Create a Payment',
      action_10: 'Close',

      resetTotp_title: 'Reset 2FA?',
      resetTotp_action: 'Reset 2FA',
      resetTotp_text: 'Are you sure you want to reset 2FA for <span>{name}<span>?',

      delete_note_title: 'Delete Note?',
      delete_note_text: 'Are you sure you want to delete this note?',
      delete_note_action: 'Delete',

      AFFILIATE_ACCOUNT_STATUS_IS_NOT_SUITABLE_ERROR: 'Account is not confirmed',
      PAYMENT_CANNOT_BE_CREATED_EARLIER_ERROR: 'Payment can\'t be created earlier than',
      AFFILIATE_HAS_NO_WALLET_ERROR: 'Wallet is not set',
      AFFILIATE_PAYMENTS_DISABLED_ERROR: 'Payments creation is disabled for this affiliate',
      AMOUNT_LOWER_THAN_MINIMUM_PAYMENT_ERROR: 'Insufficient funds to create the payment',
      SITE_PAYMENT_METHOD_IS_DISABLED_ERROR: 'Payment method disabled',
      payment_success: 'Payment will be created soon!',
      payment_fail: 'Failed to create payment!',
      aff_email_success: 'Affiliate email activated successfully!',
      aff_email_fail: 'Failed to activate affiliate email!',
    },
    // $t('affiliates.affiliatesOffers.')
    affiliatesOffers: {
      actions: 'Actions',
      getLinks: 'Get links',
      copyLinks: 'Copy Links',
      old: 'old',
      betCoefficient: 'Bet Coefficient (MIN)',
      offerName: 'Offer name',
      declineOffer: 'Decline offer request',
      unpinOffer: 'Unpin offer',
      from: 'from',
      trafficAcceptable: 'All traffic acceptable',
      allowed: 'Allowed',
      unallowed: 'Unallowed',
      except: 'Except',
      countries: 'COUNTRIES',
      failedCopyLink: 'Failed to copy link',
      copiedClipboard: 'Copied to clipboard!',
      offer_detail: 'Add offer to view details',
      edit_offer_title: 'Cancel Scheduled Changes?',
      edit_offer_msg: 'Changes to the conditions of the offer will cancel the changes that are pending come into effect',
      edit_offer_action: 'Cancel',
      unpin: 'Unpin',
      edit: 'Edit',
      cpa: 'CPA',
      global_daily_cap: 'Global Daily Cap',
      cap_players: 'Cap Players',
      cap_players_per_days: '{count} per {days} day(s)',
      global_daily_cap_val: 'Not set | {count} | {count}',
      global_hold: 'Global Hold',
      global_hold_val: 'Not set | {count} | {count}',
      rule_candiion: 'Rules & Conditions',
      rule: 'Rule',
      revshare: 'RevShare',
      rev_sport: 'RevShare Sport',
      rev_casino: 'RevShare Casino',
      payout: 'Payout',
      daily_cap: 'Daily Cap',
      hold: 'Hold',
      sub_id: 'Sub ID',
      not_equals: 'Not equals',
      equals: 'Equals',
      deposit: 'Deposits',
      deposit_count: 'Deposits Count',
      min: 'Min: {count}',
      max: 'Max: {count}',
      deposit_total: 'Total Deposits Sum',
      one_deposit: 'One Deposit Sum',
      sport_bet: 'Sport Bets',
      bet_count: 'Bets Count',
      bet_sum: 'Bets Sum',
      one_bet_amount: 'One Bet Amount',
      bet_odd: 'Bet Odd',
      casino_bet: 'Casino Bets',
      not_set: 'Not set',
      pand_change: 'Pending Changes',
      cancel_pand_change: 'Cancel Pending Changes',
      percent: '{count}%',
      uncountable: '{count}',
      pending_date: '<span>Pending Changes on</span> {date}',
      available_brands: 'Available Brands',
      available_brands_links: 'Available Brands & Links',

    },
    affiliatesGroups: {
      addGroup: 'Add Group',
      removeGroup: 'Remove Group',
      members: 'Members',
    },
    affiliateSub: {
      subAffProg: 'Sub-Affiliates Program',
      active: 'Active',
      deactivate: 'Deactivate',
      inactive: 'Inactive',
      activate: 'Activate',
      change: 'Change',
      percentOfRevenue: 'Referral Percent',
      default: 'Default',
      unpinSubAffiliate: 'Unpin sub-affiliate',
      pinSubAffiliate: 'Pin sub-affiliate',
      save: 'Save',
      new_percent: 'New Percent',
      revenue_calculation: 'Revenue Calculation',
      pin_sub_aff: 'Pin Sub-Affiliates',
      pin_sub_aff_to: 'Pin Sub-Affiliate to {email}',
      pin_to_aff: 'Pin {email} to Affiliate',
      paymentsType: {
        ngr: 'NGR',
        ggr: 'GGR',
        net_project_profit: 'Net Project Profit',
      },
      confirm: {
        title_1: 'Change Referral Percent',
        msg_1: 'Enter new percent of revenue below',

        title_2: 'Deactivate Sub-Affiliate Program?',
        msg_2: 'Are you sure you want to deactivate Sub-Affiliate Program for <span>{affiliateEmail}</span>?<br/><span class="bold">In this case all sub-affiliates will be detached from their affiliate.</span>',
        action_2: 'deactivate',

        title_3: 'Activate Sub-Affiliate Program?',
        msg_3: 'Are you sure you want to activate Sub-Affiliate Program for <span>{affiliateEmail}</span>?',
        action_3: 'activate',

        title_4: 'Pin {subAffiliateEmail} to {affiliateEmail}',
        msg_4: 'Are you sure you want to pin <span>{subAffiliateEmail}</span> to <span>{affiliateEmail}</span>?',
        action_4: 'Pin',

        title_5: 'Unpin {subAffiliateEmail} from {affiliateEmail}',
        msg_5: 'Are you sure you want to unpin <span>{subAffiliateEmail}</span> from <span>{affiliateEmail}</span>?',
        action_5: 'Unpin',

        title_6: 'Unpin {subAffiliateEmail} from {affiliateEmail}',
        msg_6: 'Are you sure you want to unpin offer <span>{subAffiliateEmail}</span> from <span>{affiliateEmail}</span>?',
        action_6: 'Unpin',

        title_7: 'Unpin {subAffiliateEmail} from {affiliateEmail}',
        msg_7: 'Are you sure you want to unpin offer <span>{subAffiliateEmail}</span> from <span>{affiliateEmail}</span> and pin players to offer <span>{offer}</span>?',
        action_7: 'Unpin',
      },
    },
    affiliatePromocode: {
      add_promocode: 'Add Promo Code',
      edit_promocode: 'Edit',
      delete_promocode: 'Delete',
      statistics_promocode: 'Statistics',
      popup: {
        add_title: 'Add Promo Code',
        add_button: 'Add Promo Code',
        add_text: 'Input promo code and choose an offer below',
        edit_title: 'Edit Promo Code',
        edit_button: 'Save',
        edit_text: 'Edit promo code and choose a new offer',
        delete_title: 'Delete Promo Code',
        delete_button: 'Delete Promo Code',
        delete_text: 'Are you sure you want to delete <span>{name}</span>?',
        offer_text: 'Offer',
        label_text: 'Promo Code',
        cancel: 'Cancel',
      },
    },
    affiliatePayments: {
      info: 'Info',
      statusFilter: {
        placeholder: 'All Statuses',
        moderation: 'Payments Moderation',
        payout: 'Payments Payout',
        on_review: 'On Review',
        approved: 'Approved',
        declined: 'Declined',
        not_paid: 'Not Paid',
        for_rework: 'For Rework',
        processing: 'Processing',
        paid: 'Paid',
        failed: 'Failed',
        multiaccounts: 'Multiaccounts',
      },
      columns: {
        created_at: {
          title: 'Created at',
          fullTitle: 'Created at',
          tip: '',
          tooltip: '',
        },
        amount: {
          title: 'Amount',
          fullTitle: 'Amount',
          tip: '',
          tooltip: '',
        },
        status: {
          title: 'Status',
          fullTitle: 'Status',
          tip: '',
          tooltip: '',
        },
        payment_period_end: {
          title: 'Payment period',
          fullTitle: 'Payment period',
          tip: '',
          tooltip: '',
        },
        approved_by_admin_name: {
          title: 'Approved by',
          fullTitle: 'Approved by',
          tip: '',
          tooltip: '',
        },
        payment_method: {
          title: 'Method',
          fullTitle: 'Method',
          tip: '',
          tooltip: '',
        },
        payment_wallet: {
          title: 'Wallet',
          fullTitle: 'Wallet',
          tip: '',
          tooltip: '',
        },
        paid_at: {
          title: 'Paid at',
          fullTitle: 'Paid at',
          tip: '',
          tooltip: '',
        },
      },
    },
    balanceHistory: {
      transactions: {
        add: 'Income',
        chargeback: 'Chargeback',
        withdraw: 'Withdrawal',
        withdraw_abort: 'Withdrawal abort',
        add_bonus: 'Bonus',
      },
      columns: {
        grouping: {
          title: 'Date',
          fullTitle: 'Date',
          tip: '',
          tooltip: '',
        },
        transaction_type: {
          title: 'Transaction Type',
          fullTitle: 'Transaction Type',
          tip: '',
          tooltip: '',
        },
        transaction_description: {
          title: 'Description',
          fullTitle: 'Transaction Description',
          tip: '',
          tooltip: '',
        },
        amount_to_hold: {
          title: 'To Hold',
          fullTitle: 'To Hold',
          tip: '',
          tooltip: '',
        },
        amount_to_available: {
          title: 'To Available',
          fullTitle: 'To Available',
          tip: '',
          tooltip: '',
        },
        result_balance_hold: {
          title: 'Hold Balance',
          fullTitle: 'Hold Balance',
          tip: '',
          tooltip: '',
        },
        result_balance_available: {
          title: 'Available Balance',
          fullTitle: 'Available Balance',
          tip: '',
          tooltip: '',
        },
      },
    },
    resetPassword: {
      newPassword: 'New Password',
      repeatPassword: 'Repeat New Password',
      error_1: 'Passwords do not match',

      title_1: 'Reset Password for {affiliateEmail}',
      msg_1: 'Input new password for <span>{affiliateEmail}</span> and confirm it',
      action_1: 'Reset Password',
    },
    estimatedPayment: {
      title: 'Estimated Payment',
      total: 'Total',
      next_payment: 'Next Payment',
      offer_static: 'Offer Statistics',
      players: 'Players',
      offer: 'Offer',
      revshare: 'RevShare',
      cpa: 'CPA',
      cpa_player: 'CPA Players',
      hide_stats: 'Hide stats after conversion',
      all_offer: 'All Offers',
      col_select: 'Select columns',
      for_net: 'for network',
      playersFilter: {
        to_be_paid_in_next_payment: {
          title: 'In Next Payment',
        },
        to_be_paid_after_next_payment: {
          title: 'After Next Payment',
        },
        all_unpaid_players: {
          title: 'Not Paid Players',
        },
        all_paid_players: {
          title: 'Paid Players',
        },
        all_players: {
          title: 'All Players',
        },
      },
      cpaPlayers: {
        fields: {
          site_player_id: {
            title: 'Player ID',
          },
          external_id: {
            title: 'External ID',
          },
          subid1: {
            title: 'SubID 1',
          },
          subid2: {
            title: 'SubID 2',
          },
          subid3: {
            title: 'SubID 3',
          },
          subid4: {
            title: 'SubID 4',
          },
          subid5: {
            title: 'SubID 5',
          },
          player_country_name: {
            title: 'Country',
          },
          registration_timestamp: {
            title: 'Registration Date',
          },
          site_registration_type_name: {
            title: 'Registration Type',
          },
          latest_activity_timestamp: {
            title: 'Latest Activity',
          },
          latest_deposit_timestamp: {
            title: 'Latest Deposit',
          },
          first_deposit_timestamp: {
            title: 'First Depo. Date',
          },
          conversion_timestamp: {
            title: 'Conversion Date',
          },
          hold_expiration_timestamp: {
            title: 'Hold Expir. Date',
          },
          top_deposits_payment_system_name: {
            title: 'Deposit Top PS',
          },
          top_withdrawals_payment_system_name: {
            title: 'Withdrawal Top PS',
          },
          deposits_count: {
            title: 'Deposits Count',
            tip: 'Count',
          },
          deposits_sum: {
            title: 'Deposits Sum',
            tip: 'Sum',
          },
          withdrawals_count: {
            title: 'Withdrawals Count',
            tip: 'Count',
          },
          withdrawals_sum: {
            title: 'Withdrawals Sum',
            tip: 'Sum',
          },
          sport_bets_count: {
            title: 'Sport Bets Count',
            tip: 'Count',
          },
          sport_bets_sum: {
            title: 'Sport Bets Sum',
            tip: 'Sum',
          },
          avg_sport_bets_odd: {
            title: 'Sport Bets Avg Odd',
            tip: 'Avg Odd',
          },
          median_sport_bets_odd: {
            title: 'Sport Bets Med. Odd',
            tip: 'Med. Odd',
          },
          games_bets_count: {
            title: 'Casino Bets Count',
            tip: 'Count',
          },
          games_bets_sum: {
            title: 'Casino Bets Sum',
            tip: 'Sum',
          },
          betting_profit: {
            title: 'Betting Profit',
          },
          cpa_revenue: {
            title: 'CPA Revenue',
          },
          revshare_revenue: {
            title: 'RS Revenue',
          },
          revenue: {
            title: 'Revenue',
          },
          total_profit: {
            title: 'Total',
          },
          days_since_reg: {
            title: 'Days Since Reg',
          },
          days_active: {
            title: 'Days Active',
          },
        },
      },
    },
    confirn_rotation_set_main: {
      title: 'Set Group as Main?',
      text: 'Are you sure you want to set this group as main',
    },
    confirn_rotation_delete: {
      title: 'Detach Group?',
      text: 'Are you sure you want to detach this group from affiliate?',
    },

    media_buying_setting_card: {
      mb_settings: 'Media Buying Settings',
      pending_balance: 'Pending Balance',
      balance_limit: 'Balance Limit',
      available_balance: 'Available Unreported Balance',
      amount_up_to: 'Amount up to',
      auto_approve_post: 'Auto Approve for Posts',
      cost_per_player_up: 'Cost per Player up to',
      cost_per_player: 'Cost per Player',
      amount: 'Amount',
      disabled: 'Disabled',
      enabled: 'Enabled',
    },
  },
};
