<template>
  <div v-loading="isDataLoading" class="affiliate-offers">
    <add-offer-popup
      v-show="isPopupOpen"
      :is-open="isPopupOpen"
      :action="action"
      :data="data"
      :affiliate="affiliateId"
      :customer-currencies.sync="customerCurrencies"
      :currency.sync="defaultCurrency"
      @close="onClosePopup"
    />
    <pending-changes
      v-show="isPendingPopupOpen"
      :is-open="isPendingPopupOpen"
      :offer-info="offerInfo"
      :is-data-loading="isDataLoading"
      :is-edit-offer="isEditOffer"
      @close="onClosePendingPopup"
      @cancelScheduledChanges="cancelScheduledChanges(offerInfo.id)"
    />
    <offer-delete-confirm
      v-show="isPopupOpenConfirm"
      :is-open="isPopupOpenConfirm"
      :data="offerInfo"
      @unpin="deleteOffer"
      @close="onClosePopupConfirm"
    />
    <ui-confirm
      ref="confirm"
      :width="480"
      :loading-action="loadingBtnConfirm"
      :type="confirmType"
      :action-name="actionName"
      :action="confirmAction"
      :action-icon="actionIcon"
      :action-fa-icon="actionFaIcon"
      @close="closeConfirmHandler"
      @save="confirmHandler"
    >
      <div slot="title" v-html="confirmTitle" />
      <div class="body__wrap">
        <i :class="confirmType" class="fas fa-exclamation-triangle" />
        <div class="body__msg" v-html="confirmMsg" />
      </div>
    </ui-confirm>
    <div class="wrapper">
      <div class="info">
        <div class="blocks-left">
          <!-- click offer card -->
          <el-popover
            ref="offerSelect"
            v-model="isOfferSelectOpen"
            placement="bottom"
            trigger="manual"
            popper-class="links-offer-poppover"
          >
            <ui-button
              v-show="isCreateOffer"
              slot="reference"
              size="big"
              color="green"
              class="edit-btn offer-card offer-card__mini"
              @click="addOffer"
            >
              + {{ $t('affiliates.cardView.toolbar.btns.addOffer') }}
              <i v-if="linksOffer.length > 1 && isOfferSelectOpen" class="ui-chevron far fa-angle-up chevron" />
              <i v-if="linksOffer.length > 1 && !isOfferSelectOpen" class="ui-chevron far fa-angle-down chevron" />
            </ui-button>
            <div class="links-offer-wrapper">
              <div
                v-for="(item, index) in linksOffer"
                :key="index"
                class="links-offer"
                @click="editOfferPopup(item)"
              >
                <div> {{ item.name }} </div>
              </div>
            </div>
          </el-popover>
          <div v-if="isOfferLoading && affiliateOffersList.length !== 0">
            <div
              v-for="(item, index) in affiliateOffersList"
              :key="index"
              :class="{ 'offer-card__active': isActiveOfferCard === index }"
              class="offer-card offer-card__mini"
              @click="activeOffer(item.id, index)"
            >
              <div>
                <span class="text-fade">ID: {{ item.incrementing_id }}</span>
                <h5 class="offer-card__title-inner">
                  {{ item.name }}
                </h5>
              </div>
              <span
                v-if="item.scheduled_changes_effective_timestamp !== null"
                class="offer-card__pending-offer"
                @click="pendingChangesPopup()"
              >
                {{ $t('affiliates.affiliatesOffers.pand_change') }}
              </span>
            </div>
          </div>
        </div>
        <!-- .......right -->
        <offer-info
          :is-offer-loading="isOfferLoading"
          :is-list="affiliateOffersList.length !== 0"
          :is-edit-offer="isEditOffer"
          :is-delete-offer="isDeleteOffer"
          :loading-btn="loadingBtn"
          :offer-info="offerInfo"
        />
      </div>
    </div>
  </div>
</template>

<script>
import offerTemplate from './helper/offer-template';
import AddOfferPopup from '@/components/Popups/AddOfferPopup.vue';
import PendingChanges from '@/components/Popups/PendingChanges.vue';
import OfferDeleteConfirm from '@/components/Popups/OfferDeleteConfirm.vue';
import OfferInfo from '@/components/OfferInfo.vue';
import app from '@/main';

export default {
  name: 'AffiliateOffers',
  components: {
    AddOfferPopup,
    OfferDeleteConfirm,
    PendingChanges,
    OfferInfo,
  },
  filters: {
    notSet(v, tr) {
      if (v === 0) {
        return tr('affiliates.affiliatesOffers.not_set');
      }
      return v;
    },
  },
  data() {
    return {
      currentOfferCardBrand: '',
      changesEffTimestampPopover: [],
      affiliateId: '',
      customerCurrencies: [],
      linksOffer: [],
      linksOfferPermissions: {},
      isOfferSelectOpen: false,
      data: {},
      wallets: {},
      isOffersVisible: false,
      permissions: {},
      action: '',
      isPopupOpen: false,
      isPendingPopupOpen: false,
      offerInfo: {},
      isActiveOfferCard: 0,
      offerRequests: [],
      affiliateOffersList: [],
      isOfferLoadingForPopup: true,
      isDataLoading: true,
      isOfferLoading: false,
      isOfferReqLoading: false,
      isLoadingOfferInfo: false,

      searchText: '',
      info: {},
      // popup confirm
      confirmMsg: '',
      confirmAction: '',
      confirmTitle: '',
      confirmType: '',
      actionName: '',
      actionFaIcon: '',
      actionIcon: '',
      isPopupOpenConfirm: false,
      loadingBtn: false,
      loadingBtnConfirm: false,
      offerId: '',
    };
  },
  computed: {
    defaultCurrency() {
      return this.wallets.currency_code || '';
    },
    isCreateOffer() {
      return this.permissions.edit_offers;
    },
    isEditOffer() {
      return !!this.linksOfferPermissions.edit;
    },
    isDeleteOffer() {
      return !!this.linksOfferPermissions.delete;
    },
  },

  async mounted() {
    app.$eventBus.$on('checkUnpin', this.checkUnpin);
    app.$eventBus.$on('editOffer', scheduled_hanges => this.editOffer(scheduled_hanges));
    document.addEventListener('click', this.checkCurElementClick, true);

    this.affiliateId = this.$route.params.id;
    if (!this.affiliateId) {
      this.$router.push('/affiliates/list');
    }
    try {
      const { data } = await this.$api.getAffiliateInfo(this.affiliateId);
      this.info = data.payload;
      this.permissions = data.misc.permissions;

      if (this.permissions.edit_offers) {
        this.$api.getMiscCustomerCurrencies().then((res) => {
          this.customerCurrencies = res.data.payload;
        });
      }
      const { data: dataTemplate } = await this.$api.getAffiliateOfferTemplates();
      this.linksOffer = dataTemplate.payload;
      await this.getOffers();
      await this.fetchWallets();
    } finally {
      this.isDataLoading = false;
    }
  },

  beforeDestroy() {
    app.$eventBus.$off('checkUnpin', this.checkUnpin);
    app.$eventBus.$off('editOffer', this.editOffer);
    document.removeEventListener('click', this.checkCurElementClick);
  },

  methods: {
    async fetchWallets() {
      const response = await this.$api.getAffiliateWallets(this.affiliateId);

      this.wallets = response.data.payload.find(wallet => wallet.status === 'active');
    },
    checkCurElementClick(e) {
      if (this.isOfferSelectOpen && this.$refs?.offerSelect?.$el.contains(e.target)) return;
      this.isOfferSelectOpen = false;
    },

    async getOffers(isSetLastAddedIndex = false) {
      this.isDataLoading = true;
      this.isOfferLoading = false;
      try {
        const { data } = await this.$api.getAffiliateOffers(this.affiliateId);
        this.affiliateOffersList = data.payload;
        if (this.affiliateOffersList.length !== 0) {
          if (isSetLastAddedIndex) {
            this.onSetActiveLastAddedOffer();
          }
          await this.activeOffer(
            this.affiliateOffersList[this.isActiveOfferCard].id,
            this.isActiveOfferCard,
          );
        }
        this.changesEffTimestampPopover = new Array(
          this.affiliateOffersList.length,
        ).fill(false);
        return data;
      } finally {
        this.isDataLoading = false;
        this.isOfferLoading = true;
      }
    },

    genCpaParams(cpa) {
      const {
        amount,
        currency_code,
        cap_period_seconds,
        cap_players_count,
        hold_seconds,
        conditions,
      } = cpa;
      const values = conditions.reduce((acc, item) => {
        acc[`${item.name}_min`] = item.min_value;
        acc[`${item.name}_max`] = item.max_value;

        return acc;
      }, {});
      return {
        amount,
        currency_code,
        cap_period_seconds,
        cap_players_count,
        hold_seconds,
        ...values,
      };
    },

    async activeOffer(offerId, count) {
      try {
        this.isOfferLoadingForPopup = true;
        const response = await this.$api.getAffiliateOffer(offerId);
        this.isActiveOfferCard = count;
        this.searchText = '';
        this.offerInfo = {
          ...this.$_.cloneDeep(response.data.payload),
          payout_rules: this.$_.cloneDeep(response.data.payload).payout_rules.map(elem => ({
            ...elem,
            settings: {
              ...elem.settings,
              rev_share: elem.settings.rev_share ? {
                sport_levels: elem.settings.rev_share.sport_levels,
                casino_levels: this.$_.isEqual(elem.settings.rev_share.sport_levels, elem.settings.rev_share.casino_levels)
                  ? null : elem.settings.rev_share.casino_levels,
              } : null,
              cpa: elem.settings.cpa?.map(this.genCpaParams) || null,
            },
          })),
        };
        this.linksOfferPermissions = response.data.misc.permissions;
      } catch (error) {
        this.errors = this.$_.cloneDeepWith(error.data.errors);
      } finally {
        this.isOfferLoadingForPopup = false;
      }
    },

    addOffer() {
      this.action = 'Add';
      if (this.linksOffer.length > 1) {
        this.isOfferSelectOpen = !this.isOfferSelectOpen;
      } else if (this.linksOffer.length < 1) {
        this.editOfferPopup(offerTemplate);
      } else {
        this.editOfferPopup(this.linksOffer[0]);
      }
    },

    updateInfo(isSetLastAddedIndex = false) {
      this.getOffers(isSetLastAddedIndex);
      this.$api.getAffiliateInfo(this.affiliateId).then((response) => {
        this.$emit('update:info', this.$_.cloneDeepWith(response.data.payload));
      });
    },

    onSetActiveLastAddedOffer() {
      const lastAdded = this.affiliateOffersList.reduce((acc, list, index) => {
        if (!acc.incrementing_id || list.incrementing_id > acc.incrementing_id) {
          return {
            ...list,
            index,
          };
        }
        return acc;
      }, {});

      this.isActiveOfferCard = lastAdded.index;
    },
    onClosePopup(action, isLastAdded) {
      this.isPopupOpen = false;
      if (action === 'Add') {
        this.isActiveOfferCard = this.affiliateOffersNew?.length || 0;
      }
      this.updateInfo(isLastAdded);
    },

    cancelScheduledChanges(id, flag) {
      return this.$api.cancelScheduledChanges(id).then(() => {
        this.getOffers().finally(() => {
          this.$refs.confirm.close();
          this.loadingBtnConfirm = false;
          if (flag === 'del') {
            setTimeout(() => {
              document.querySelector('#app').classList.remove('offer-ovf');
              this.editOffer();
            });
          }
        });
      });
    },
    // /popup confirm
    pendingChangesPopup() {
      this.isPendingPopupOpen = true;
    },
    onClosePendingPopup() {
      this.isPendingPopupOpen = false;
    },
    onClosePopupConfirm() {
      this.isPopupOpenConfirm = false;
      this.updateInfo();
    },

    deleteOffer(v, type, nameNewOffer) {
      this.offerId = v;
      this.onConfirm(type, nameNewOffer);
    },

    onConfirm(type, nameNewOffer) {
      if (type === 'confirm') {
        this.confirmTitle = `${this.$t(
          'affiliates.affiliateSub.confirm.title_7',
          {
            subAffiliateEmail: this.offerInfo.name,
            affiliateEmail: this.info.email,
          },
        )}`;
        this.confirmMsg = `${this.$t('affiliates.affiliateSub.confirm.msg_7', {
          subAffiliateEmail: this.offerInfo.name,
          affiliateEmail: this.info.email,
          offer: nameNewOffer,
        })}`;
        this.actionName = `${this.$t(
          'affiliates.affiliateSub.confirm.action_7',
        )}`;
        this.confirmAction = '';
        this.confirmType = 'warning';
        this.actionFaIcon = 'unlink';
        this.$refs.confirm.open();
      }
      if (type === 'unpin') {
        this.confirmTitle = `${this.$t(
          'affiliates.affiliateSub.confirm.title_6',
          {
            subAffiliateEmail: this.offerInfo.name,
            affiliateEmail: this.info.email,
          },
        )}`;
        this.confirmMsg = `${this.$t('affiliates.affiliateSub.confirm.msg_6', {
          subAffiliateEmail: this.offerInfo.name,
          affiliateEmail: this.info.email,
        })}`;
        this.actionName = `${this.$t(
          'affiliates.affiliateSub.confirm.action_6',
        )}`;
        this.confirmAction = '';
        this.confirmType = 'warning';
        this.actionFaIcon = 'unlink';
        this.$refs.confirm.open();
      }
    },

    closeConfirmHandler(action) {
      if (action !== undefined) {
        setTimeout(() => {
          document.querySelector('#app').classList.remove('offer-ovf');
        }, 200);
      }
    },

    confirmHandler(action) {
      if (action === 'pending') {
        this.loadingBtnConfirm = true;
        this.cancelScheduledChanges(this.offerInfo.id, 'del');
      } else {
        this.unpinOffer();
      }
    },

    editOffer(v) {
      if (v) {
        document.querySelector('#app').classList.add('offer-ovf');
        this.confirmTitle = `${this.$t(
          'affiliates.affiliatesOffers.edit_offer_title',
        )}`;
        this.confirmMsg = `${this.$t(
          'affiliates.affiliatesOffers.edit_offer_msg',
        )}`;
        this.actionName = `${this.$t(
          'affiliates.affiliatesOffers.edit_offer_action',
        )}`;
        this.confirmAction = 'pending';
        this.confirmType = 'success';
        this.actionFaIcon = 'check';
        this.$refs.confirm.open();
      } else {
        this.action = 'Edit';
        this.isPopupOpen = true;
        this.data = this.offerInfo;
      }
    },

    editOfferPopup(item) {
      this.action = 'Add';
      this.isOfferSelectOpen = false;
      this.isPopupOpen = true;
      let itemNew = item;
      const settings = item.template_settings;
      itemNew = this.$_.merge(itemNew, settings);

      // add default currency code USD
      itemNew.payout_rules = itemNew.payout_rules.map(r => ({
        ...r,
        settings: {
          ...r.settings,
          rev_share: r.settings.rev_share ? {
            sport_levels: r.settings.rev_share.sport_levels,
            casino_levels: this.$_.isEqual(r.settings.rev_share.sport_levels, r.settings.rev_share.casino_levels)
              ? null : r.settings.rev_share.casino_levels,
          } : null,
          cpa: r.settings?.cpa?.map(cpa => ({
            currency_code: this.defaultCurrency,
            ...cpa,
          })) || null,
        },
      }));

      if (
        this.$store.getters['auth/currentAcl'].offers_revshare_edit_settings !== 'allow' && localStorage.getItem('active-team')
      ) {
        itemNew.payout_rules[0].settings.rev_share.sport_levels = {};
        itemNew.payout_rules[0].settings.rev_share.casino_levels = {};
      }

      delete itemNew.template_settings;
      this.data = itemNew;
    },

    checkUnpin() {
      this.loadingBtn = true;
      this.$api
        .getAffiliateOfferBeforeDelete(this.offerInfo.id)
        .then((res) => {
          const removeAllowed = res.data.payload.need_to_set_new_offer;
          if (removeAllowed) {
            this.isPopupOpenConfirm = true;
          } else {
            this.onConfirm('unpin');
          }
        })
        .finally(() => {
          this.loadingBtn = false;
        });
    },

    unpinOffer() {
      const q = {
        new_affiliate_offer_id: this.offerId,
      };
      this.isDataLoading = true;
      this.$api.unpinAffiliateOffer(this.offerInfo.id, q).then(() => {
        this.isActiveOfferCard = 0;
        this.$refs.confirm.close();
        this.isPopupOpenConfirm = false;
        this.offerId = '';
        this.updateInfo();
      });
    },

    // getHoldRevShare(item) {
    //   return item.settings.hold_seconds || item.settings.hold_seconds_rev_share;
    // },
    // getHoldDeposit(item) {
    //   return item.settings.hold_seconds || item.settings.hold_seconds_deposit;
    // },
  },
};
</script>

<style lang="scss" scoped>
.chevron {
  font-weight: 800!important;
  font-size: 10px;
  color: var(--charcoal);
}

.links-offer-wrapper {
  border-radius: 3px;
  overflow: hidden;
  font-size: 14px;
  color: var(--charcoal);
}
.links-offer {
  display: flex;
  align-items: center;
  width: 358px;
  height: 34px;
  padding-left: 19px;

  div {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &:hover {
    background-color: var(--primary-color-alpha-10);
    cursor: pointer;
  }
}

.affiliate-offers {
  .wrapper {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    min-width: 1140px;
    width: 90%;
  }
}
</style>

<style lang="scss">
.links-offer-poppover {
  padding: 0!important;
}
</style>
